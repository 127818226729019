// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import animationData from "../../lotties/bubble.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
type Props = {
  width?: number;
  height?: number;
};

const LottieAnimation: React.FC<Props> = ({ width = 600, height = 600 }) => {
  return (
    <Lottie
      options={defaultOptions}
      isClickToPauseDisabled={true}
      height={height}
      width={width}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    />
  );
};

// Setzen von Default-Props, falls keine Props übergeben werden
LottieAnimation.defaultProps = {
  width: 600,
  height: 600,
};

LottieAnimation.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LottieAnimation;
