import { ArrowUp } from "@phosphor-icons/react";
import { fetchAuthSession } from "aws-amplify/auth";
import React, { useContext, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import untruncateJson from "untruncate-json";
import { v4 as uuidv4 } from "uuid";
import { Feedback } from "./Feedback";
import { UserSession } from "../../ApiClient";
import { MyContext } from "../../App";
import { ArcBotTableItem } from "../../components/arcbot-table-item";
import { Header } from "../../components/Header";
import NewEnvironmentDialog from "../../components/NewEnvironmentDialog";
import { NinoxDatabaseInput } from "../../components/ninox-database-input";
import { PoliciesDialog } from "../../components/policies-dialogue";
import PromptUserOutputItem from "../../components/prompt-user-output-item";
import { Avatar, AvatarFallback } from "../../components/ui/avatar";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import LottieAnimation from "../../components/ui/loadingBubble";
import Logo from "../../components/ui/logo";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "../../components/ui/resizable";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { Switch } from "../../components/ui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";
import { Textarea } from "../../components/ui/textarea";
import NinoxClient from "../../ninox-client";
import { NinoxTableUpdate } from "../../ninox-types";
import { Conversation } from "../../types";

const setSearchParam = (param: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value);
  // Keep existing params by using the current URL's search params
  const currentParams = new URLSearchParams(window.location.search);
  currentParams.forEach((existingValue, key) => {
    if (key !== param) {
      url.searchParams.set(key, existingValue);
    }
  });
  window.history.pushState({}, "", url.toString());
};

export const ArcBotAnswerWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="flex gap-4 space-x-2 align-top prompt-user-output-item items-top">
    <Avatar className="flex-shrink-0 bg-primary">
      <AvatarFallback>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 256 256"
        >
          <path d="M208,144a15.78,15.78,0,0,1-10.42,14.94l-51.65,19-19,51.61a15.92,15.92,0,0,1-29.88,0L78,178l-51.62-19a15.92,15.92,0,0,1,0-29.88l51.65-19,19-51.61a15.92,15.92,0,0,1,29.88,0l19,51.65,51.61,19A15.78,15.78,0,0,1,208,144ZM152,48h16V64a8,8,0,0,0,16,0V48h16a8,8,0,0,0,0-16H184V16a8,8,0,0,0-16,0V32H152a8,8,0,0,0,0,16Zm88,32h-8V72a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16Z"></path>
        </svg>
      </AvatarFallback>
    </Avatar>
    <div className="flex flex-col flex-grow overflow-x-auto">
      <div className="flex flex-col prompt-output-header">
        <p className="font-semibold prompt-user-name">arcBot</p>
      </div>
      <div className="prompt-output-body">{children}</div>
    </div>
  </div>
);

export const ArcBotAppRoute = "/";

const truncateTitle = (title: string | undefined, maxLength: number = 30) => {
  if (!title) return "";
  return title.length > maxLength
    ? `${title.substring(0, maxLength - 3)}...`
    : title;
};

const removeFirstAndLastLines = (text: string) => {
  const lines = text.split("\n");
  return lines.slice(1, -1).join("\n");
};

export function ArcBotApp() {
  const [prompt, setPrompt] = useState("");
  const [answer, setAnswer] = useState("");
  const [reactCode, setReactCode] = useState<string>();
  const [previewHtml, setPreviewHtml] = useState("<div>So far empty</div>");
  const [loading, setLoading] = useState(false);
  const [responseHasError, setResponseHasError] = useState(false);
  const [chatHistory, setChatHistory] = useState<Conversation[]>([]);
  const [rating, setRating] = useState<boolean>();
  const [comment, setComment] = useState<string>();
  const [submitted, setSubmitted] = useState(false);
  const [userSessions, setUserSessions] = useState<UserSession[]>([]);
  const [sessionId, setSessionId] = useState<string>(uuidv4());
  const [isNewEnvironmentDialogOpen, setIsNewEnvironmentDialogOpen] =
    useState(false);
  const [databaseNames, setDatabaseNames] = useState<{
    [key: string]: string;
  }>({});
  const [tables, setTables] = useState<any>();
  const [showMergePrompt, setShowMergePrompt] = useState(false);
  const [isDatabaseView, setIsDatabaseView] = useState(true);
  const [view, setView] = useState<"database" | "react" | "preview">(
    "database"
  );

  const navigate = useNavigate();

  const { sessionId: sessionIdParam } = useParams<{ sessionId: string }>();

  const {
    ninoxTables,
    setLoginOpen,
    setNinoxTables,
    setGlobalCode,
    signedInUser,
    stage,
    apiClient,
  } = useContext(MyContext);

  const handleOpenDialog = () => {
    setIsNewEnvironmentDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsNewEnvironmentDialogOpen(false);
  };

  const updateChatHistory = async () => {
    const userEvents = await apiClient.getUserEvents({
      sessionId: sessionIdParam ?? "",
    });
    const sessionChatHistory: Conversation[] = userEvents.data.map((event) => ({
      user: event.userInput ?? undefined,
      ...(event.arcBotAnswer && {
        arcbot: {
          answer: decodeURIComponent(event.arcBotAnswer),
        },
      }),
    }));
    setChatHistory(sessionChatHistory);
  };

  const getUserSessions = async () => {
    const sessions = await apiClient.getUserSessions();
    setUserSessions(sessions.data);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const promptParam = url.searchParams.get("prompt");
    const viewParam = url.searchParams.get("view");
    if (promptParam) {
      setPrompt(promptParam);
    }
    if (viewParam) {
      setIsDatabaseView(viewParam === "database");
    }
    const localStorageAcceptedPolicies = localStorage.getItem("acceptedTerms");
    setAcceptedTerms(localStorageAcceptedPolicies === "true");
  }, []);

  useEffect(() => {
    if (sessionIdParam) {
      setSessionId(sessionIdParam ?? uuidv4());
    }
    if (signedInUser && sessionId) {
      void getUserSessions();
      void updateChatHistory();
    }
  }, [signedInUser, sessionId, sessionIdParam]);

  useEffect(() => {
    if (signedInUser) {
      signedInUser.ninoxEnvironments.map((ninoxEnvironment, index) => {
        const client = new NinoxClient(
          ninoxEnvironment.cloudUrl,
          ninoxEnvironment.apiKey
        );

        void (async () => {
          try {
            client.setTeamId(ninoxEnvironment.teamId);
            const envDatabases = await client.getDatabases();
            const selectedDatabase = envDatabases.find(
              (db) => db.id === ninoxEnvironment.databaseId
            );
            if (selectedDatabase) {
              databaseNames[ninoxEnvironment.databaseId] =
                selectedDatabase.name;
            }
          } catch (err) {
            console.log(
              `ninox env invalid for: cloudUrl ${ninoxEnvironment.cloudUrl}; teamId ${ninoxEnvironment.teamId}; database ${ninoxEnvironment.databaseId}`
            );
          }
        })();
      });
    }
  }, [signedInUser]);

  useEffect(() => {
    if (answer) {
      console.log(answer);
      // get the react code block
      const codeBlockRegex = /```tsx.*\n([\s\S]*?)```/;
      const match = answer.match(codeBlockRegex);

      if (match && match[1]) {
        const code = match[1].trim();
        setReactCode(code);
        setView("react");
      } else {
        // setReactCode("");
      }

      // get the html code block
      const htmlBlockRegex = /```html([\s\S]*?)```/;
      const htmlMatch = answer.match(htmlBlockRegex);

      if (htmlMatch && htmlMatch[1]) {
        const html = htmlMatch[1].trim();
        setPreviewHtml(html);
      } else {
        // setPreviewHtml("");
      }
    }
  }, [answer]);

  const fetchArcbotReact = async (p: string) => {
    setSearchParam("prompt", p);
    setAnswer("");
    setLoading(true);

    try {
      const response = isDatabaseView
        ? await apiClient.askArcBot(p, ninoxTables, sessionId, stage)
        : await apiClient.askArcBotReact(
            p,
            ninoxTables,
            reactCode,
            sessionId,
            stage,
            chatHistory.map((conversation) => ({
              user: conversation.user,
              arcbot: conversation.arcbot?.answer,
            }))
          );
      if (response.status !== 200) throw new Error(response.statusText);

      const reader = response.body?.getReader();
      let currentAnswer = "";

      while (true) {
        const { done, value } = (await reader?.read()) ?? {
          done: true,
          value: undefined,
        };
        if (done) break;
        const chunk = new TextDecoder().decode(value);
        currentAnswer += chunk;
        setAnswer(currentAnswer);
      }

      const jsonTablesEnrichedRegex = /```json\s+enriched.*\n([\s\S]*?)```/g;
      const match = currentAnswer.match(jsonTablesEnrichedRegex);

      if (match && match[1]) {
        console.log("got tables");
        const jsonTables = match[1].slice(21, -3).trim();
        // console.log("try parsing answer");
        try {
          setTables(JSON.parse(jsonTables));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }

      return currentAnswer;
    } catch (error) {
      console.error(error);
      setResponseHasError(true);
      setPrompt(p);
    } finally {
      setLoading(false);
    }
  };

  const outputEndRef = useRef<HTMLDivElement>(null);

  const feedbackInput = document.getElementById(
    "feedback"
  ) as HTMLTextAreaElement;
  const [termsOpen, setTermsOpen] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const submit = async () => {
    if (!sessionId) return;

    const currentPrompt = prompt; // Store the current prompt

    // show session now in url like chatgpt does
    navigate(`/s/${sessionId}${window.location.search}`);
    await apiClient.createUserSession({
      sessionId,
      title: currentPrompt,
    });

    setComment(undefined);
    setRating(undefined);
    setSubmitted(false);

    // console.log(`sessionId=${sessionId}`);

    setChatHistory([
      ...chatHistory,
      {
        user: currentPrompt,
      },
    ]);

    // Clear the prompt after receiving the response
    setPrompt("");

    // store userInput in ninox
    await apiClient.saveSessionUserInput({
      userInput: currentPrompt,
      sessionId,
      stage,
    });
    const currentAnswer = await fetchArcbotReact(currentPrompt);

    if (!currentAnswer) return;

    setChatHistory((prevHistory) => [
      ...prevHistory,
      {
        arcbot: {
          answer: currentAnswer,
        },
      },
    ]);

    await apiClient.saveSessionArcBotAnswer({
      arcBotAnswer: currentAnswer,
      sessionId,
      stage,
    });

    await getUserSessions();

    // Show the merge prompt after the response is received
    isDatabaseView && setShowMergePrompt(true);
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 14);

  const isSameDay = (date1: any, date2: any) =>
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear();

  const sessionsToday = userSessions.filter((session) => {
    const sessionDate = new Date(session.timestamp);
    return isSameDay(sessionDate, today);
  });

  const sessionsYesterday = userSessions.filter((session) => {
    const sessionDate = new Date(session.timestamp);
    return isSameDay(sessionDate, yesterday);
  });

  const sessionsOlderThanTwoWeeks = userSessions.filter((session) => {
    const sessionDate = new Date(session.timestamp);
    return sessionDate < twoWeeksAgo;
  });

  const handleViewChange = (checked: boolean) => {
    const newView = checked ? "ui" : "database";
    setIsDatabaseView(!checked);
    setSearchParam("view", newView);
    console.log(newView);
  };

  const TextWithComponent = ({ text }: { text: string }) => {
    const jsonTablesRegex = /```json\s+enriched=false.*\n([\s\S]*?)```/g;
    const jsonTablesEnrichedRegex = /```json\s+enriched=true.*\n([\s\S]*?)```/g;
    const htmlRegex = /```html.*\n([\s\S]*?)```/g;
    const tsxRegex = /```tsx.*\n([\s\S]*?)```/g;
    const globalCodeRegex = /```txt\s+globalCode.*\n([\s\S]*?)```/g;
    let replacedText = text + "```";

    const safeJSONParse = (jsonString: string) => {
      // Escape special characters only within JSON string literals
      const escapedJson = jsonString.replace(/"(?:[^"\\]|\\.)*"/g, (match) => {
        return match.replace(/[^\u0020-\u007E]/g, (char) => {
          return "\\u" + ("0000" + char.charCodeAt(0).toString(16)).slice(-4);
        });
      });

      try {
        return JSON.parse(escapedJson);
      } catch (e) {
        console.error("Error parsing JSON:", e);
        // console.log("Problematic JSON string:", jsonString);
        return null;
      }
    };

    const arcBotTableWrapper = (parsedJson: any) => (
      <div
        className="grid w-100"
        style={{
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          justifyContent: "center",
          gridAutoRows: "auto",
          gridTemplateRows: "auto",
          gap: "10px",
        }}
      >
        {Object.entries(parsedJson).map(([key, value]: [string, unknown]) => (
          <ArcBotTableItem key={key} table={{ [key]: value as any }} />
        ))}
      </div>
    );

    const parts = replacedText.split(/(```[\s\S]*?```)/);

    const elements = parts.map((part, index) => {
      if (part.match(jsonTablesRegex) || part.match(jsonTablesEnrichedRegex)) {
        const jsonContent = part.slice(part.indexOf("\n") + 1, -3).trim();
        try {
          const untruncateJsonAnswer = untruncateJson(jsonContent);
          const parsedJson = safeJSONParse(untruncateJsonAnswer);
          console.log("parsedJson", parsedJson);
          console.log("part", part);
          if (parsedJson) {
            return arcBotTableWrapper(parsedJson);
          }
        } catch (e) {
          console.error("Error processing JSON:", e);
          console.log("Problematic JSON string:", jsonContent);
        }
        return <pre key={index}>{part}</pre>;
      } else if (part.match(globalCodeRegex)) {
        const globalCode = part.slice(part.indexOf("\n") + 1, -3).trim();
        // setGlobalCode(globalCode);
        return <pre key={index}>{globalCode}</pre>;
      } else if (part.match(tsxRegex)) {
        return (
          <div key={index}>
            <Button
              onClick={() => {
                setReactCode(removeFirstAndLastLines(part));
                setView("react");
              }}
            >
              Show
            </Button>
            <pre>{`\n${part}`}</pre>
          </div>
        );
      } else if (part.match(htmlRegex)) {
        return (
          <div key={index}>
            <Button
              onClick={() => {
                setPreviewHtml(removeFirstAndLastLines(part));
                setView("preview");
              }}
            >
              Show
            </Button>
            <pre>{`\n${part}`}</pre>
          </div>
        );
      }
      return (
        <pre className="w-full" key={index}>
          {part.replace(/\\n/g, "")}
        </pre>
      );
    });

    return <div className="flex flex-col gap-4">{elements}</div>;
  };

  if (!sessionId) return <div>404</div>;

  return (
    <div className="flex min-h-screen">
      <aside className="flex-shrink-0 h-screen p-4 overflow-y-scroll border-r w-80">
        <div className="p-4">
          <a href={ArcBotAppRoute}>
            <Logo />
          </a>
        </div>

        <Button
          variant="outline"
          className="justify-start w-full gap-2 mb-4 border-none text-custom-darkblue"
          onClick={() => {
            if (!signedInUser) {
              setLoginOpen(true);
              return;
            }
            handleOpenDialog();
          }}
        >
          <PlusIcon />
          New Environment
        </Button>
        <NewEnvironmentDialog
          isOpen={isNewEnvironmentDialogOpen}
          onClose={handleCloseDialog}
        />
        {!!signedInUser && (
          <div>
            <Select
              value={signedInUser.selectedUserEnvironmentId}
              onValueChange={async (selectedUserEnvironmentId) => {
                await apiClient.selectNinoxEnvironment({
                  environmentId: Number(selectedUserEnvironmentId),
                });
                await fetchAuthSession({ forceRefresh: true });
                navigate("/");
              }}
            >
              <SelectTrigger className="w-full mb-4">
                <SelectValue
                  placeholder="Select a database"
                  className="text-custom-darkblue"
                />
              </SelectTrigger>
              <SelectContent>
                {signedInUser?.ninoxEnvironments.map((environment, index) => (
                  <SelectItem
                    key={index}
                    value={environment.environmentId.toString()}
                  >
                    <div className="text-custom-darkblue">
                      {databaseNames[environment.databaseId]}
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <div className="bg-border h-[2px]"></div>
            <div className="pt-6 space-y-6">
              <div className="space-y-4">
                <h3 className="mb-2 text-sm font-semibold text-custom-darkblue">
                  Today
                </h3>
                <ul className="pl-2 space-y-4">
                  {sessionsToday.map((session) => (
                    <li key={session.sessionId}>
                      <Link
                        data-testid={`session-link-today-${session.sessionId}`}
                        className="block p-4 text-sm text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800"
                        onClick={async () => {
                          await updateChatHistory();
                          setSessionId(session.sessionId);
                        }}
                        to={"/s/" + session.sessionId}
                      >
                        {truncateTitle(session.title)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="space-y-4 ">
                <h3 className="mb-2 text-sm font-semibold text-custom-darkblue">
                  Yesterday
                </h3>
                <ul className="pl-2 space-y-4">
                  {sessionsYesterday.map((session) => (
                    <li key={session.sessionId}>
                      <Link
                        data-testid={`session-link-yesterday-${session.sessionId}`}
                        className="block p-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-800"
                        onClick={async () => {
                          await updateChatHistory();
                          setSessionId(session.sessionId);
                        }}
                        to={"/s/" + session.sessionId}
                      >
                        {truncateTitle(session.title)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="space-y-4 ">
                <h3 className="mb-2 text-sm font-semibold text-custom-darkblue">
                  Older then two weeks
                </h3>
                <ul className="pl-2 space-y-4">
                  <ul className="pl-2 space-y-4">
                    {sessionsOlderThanTwoWeeks.map((session) => (
                      <li key={session.sessionId}>
                        <Link
                          data-testid={`session-link-older-${session.sessionId}`}
                          className="p-4 text-sm text-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800"
                          onClick={async () => {
                            await updateChatHistory();
                            setSessionId(session.sessionId);
                          }}
                          to={"/s/" + session.sessionId}
                        >
                          {truncateTitle(session.title)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        )}
      </aside>
      <main className="flex flex-col flex-grow h-screen p-4 overflow-hidden">
        <Header />
        <div className="sticky top-0 z-10 flex items-center p-2 mb-4 space-x-2 bg-white">
          <Label htmlFor="database-ui-toggle">Database</Label>
          <Switch
            id="database-ui-toggle"
            checked={!isDatabaseView}
            onCheckedChange={handleViewChange}
          />
          <Label htmlFor="database-ui-toggle">
            UI (Experimental for creating React UI)
          </Label>
        </div>
        <ResizablePanelGroup
          className="flex flex-grow h-full overflow-hidden"
          direction="horizontal"
        >
          <ResizablePanel
            defaultSize={75}
            minSize={30}
            className="flex flex-col flex-1 p-10 overflow-hidden overflow-x-hidden content-left"
          >
            <div className="flex flex-col flex-grow h-full gap-10 p-0 m-0 overflow-x-hidden overflow-y-scroll prompt-output-container">
              {
                // if the chat history is empty and arcBot didn't start answering
                chatHistory.length === 0 && !loading && !tables && (
                  <div className="flex flex-col justify-center h-full overflow-hidden flex-flow align-center">
                    <LottieAnimation />
                    <div className="grid items-center justify-center w-full grid-cols-12 gap-4 p-4 examples">
                      <Badge
                        className="items-center w-full col-span-7 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                        onClick={() => {
                          const badgeText =
                            "Create a crm for my surfing school with 5 tables and 5 fields each table";
                          setPrompt(badgeText);
                        }}
                      >
                        <div className="w-full text-center truncate">
                          🏄‍♂️ Create a crm for my surfing school with 5 tables
                          and 5 fields each table
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "I want to organize the todos of my team. Please create tables for that.";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-5 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          ✅ I want to organize the todos of my team. Please
                          create tables for that.
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Change the adress field a table an connect it";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-4 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          🔁 Change the adress field a table an connect it
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Create 10 tables and a least 5 fields each to organize the production company that produces cars";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-8 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          🚗 Create 10 tables and a least 5 fields each to
                          organize the production company that produces cars
                        </div>
                      </Badge>
                      <Badge
                        onClick={() => {
                          const badgeText =
                            "Change the adress field to several fields";
                          setPrompt(badgeText);
                        }}
                        className="items-center w-full col-span-6 col-start-4 text-xs truncate cursor-pointer hover:bg-slate-200 text-slate-500 bg-slate-100"
                      >
                        <div className="w-full text-center truncate">
                          ➕ Change the adress field to several fields
                        </div>
                      </Badge>
                    </div>
                  </div>
                )
              }
              {chatHistory.length > 0 && (
                <div className="flex flex-col gap-4">
                  {chatHistory.map((conversation, index) =>
                    conversation.arcbot ? (
                      <ArcBotAnswerWrapper key={index}>
                        <TextWithComponent text={conversation.arcbot.answer} />
                      </ArcBotAnswerWrapper>
                    ) : (
                      <PromptUserOutputItem
                        key={index}
                        avatarType="user"
                        promptUserName={signedInUser?.userName ?? "You"}
                        promptUserInitials={signedInUser?.userInitials ?? "Y"}
                        promptUserMessage={conversation.user ?? ""}
                      />
                    )
                  )}
                  {!loading && (
                    <div className="pl-14">
                      <Feedback
                        rating={rating}
                        setRating={setRating}
                        comment={comment}
                        setComment={setComment}
                        submitted={submitted}
                        setSubmitted={setSubmitted}
                        sessionId={sessionId}
                      />
                    </div>
                  )}
                </div>
              )}
              {responseHasError && (
                <ArcBotAnswerWrapper>
                  <div className="flex gap-2">
                    <Badge variant="destructive">Error...</Badge>
                    <Button
                      onClick={() => {
                        try {
                          void submit();
                          setResponseHasError(false);
                        } catch (error) {
                          setResponseHasError(false);
                        }
                      }}
                    >
                      Retry?
                    </Button>
                  </div>
                </ArcBotAnswerWrapper>
              )}

              {
                // wait for Bedrock to start responding
                loading && isDatabaseView && JSON.stringify(tables) === "" && (
                  <ArcBotAnswerWrapper>
                    <p className="prompt-message">Thinking...</p>
                    <div
                      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                        Loading...
                      </span>
                    </div>
                  </ArcBotAnswerWrapper>
                )
              }
              {
                // Bedrock is responding but not finished yet
                loading && answer !== "" && (
                  <div className="">
                    <ArcBotAnswerWrapper>
                      <TextWithComponent text={answer} />
                    </ArcBotAnswerWrapper>
                  </div>
                )
              }
              {!loading && isDatabaseView && tables && showMergePrompt && (
                <div className="flex items-center justify-between gap-4">
                  <div className="flex flex-col gap-3 pl-14 prompt-merge-action">
                    <div className="flex gap-2">
                      <Label>Merge with database?</Label>
                    </div>
                    <div className="flex gap-2">
                      <Button
                        className="bg-transparent border-2 text-custom-purple border-custom-purple hover:bg-custom-purple-100 hover:border-custom-purple-hover"
                        onClick={() => {
                          setShowMergePrompt(false);
                        }}
                      >
                        No
                      </Button>
                      <Button
                        onClick={() => {
                          if (!signedInUser) {
                            setLoginOpen(true);
                            return;
                          }
                          console.log(tables);
                          const newDatabase: Record<string, NinoxTableUpdate> =
                            {
                              ...ninoxTables,
                              ...tables.types,
                            };
                          setNinoxTables(newDatabase);
                          setGlobalCode(tables.globalCode);
                          setShowMergePrompt(false);
                        }}
                      >
                        Yes
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              <div ref={outputEndRef} />
            </div>
            <div className="relative mt-6 prompt-input-container">
              <Button
                disabled={prompt.trim() === ""}
                className="absolute right-2"
                style={{ bottom: "5px" }}
                onClick={async (event) => {
                  if (prompt.trim() !== "") {
                    await submit();
                  }
                }}
              >
                <ArrowUp size={20} color="#ffffff" weight="fill" />
              </Button>
              <Textarea
                rows={1}
                className="min-h-[50px] pt-4 pr-10 resize-none max-h-10"
                placeholder="Describe the fields and tables for your database..."
                value={prompt}
                onFocus={() => {
                  if (!acceptedTerms) setTermsOpen(true);
                }}
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                onKeyDown={async (event) => {
                  if (event.key === "Enter" && !event.shiftKey) {
                    event.preventDefault(); // Prevent default behavior (new line)
                    if (prompt.trim() !== "") {
                      await submit();
                    }
                  }
                }}
              />
              <PoliciesDialog
                termsOpen={termsOpen}
                setTermsOpen={setTermsOpen}
              />
            </div>
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel>
            <div className="flex-1 h-full p-0 overflow-auto content-right bg-slate-100">
              {isDatabaseView && <NinoxDatabaseInput />}
              {!isDatabaseView && (
                <Tabs value={view} className="w-full">
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger
                      onClick={() => setView("database")}
                      value="database"
                    >
                      Database
                    </TabsTrigger>
                    <TabsTrigger onClick={() => setView("react")} value="react">
                      React
                    </TabsTrigger>
                    <TabsTrigger
                      onClick={() => setView("preview")}
                      value="preview"
                    >
                      Preview
                    </TabsTrigger>
                  </TabsList>
                  <TabsContent value="database">
                    <NinoxDatabaseInput />
                  </TabsContent>
                  <TabsContent value="react">
                    <SyntaxHighlighter language="jsx" style={vscDarkPlus}>
                      {reactCode ?? ""}
                    </SyntaxHighlighter>
                  </TabsContent>
                  <TabsContent value="preview">
                    <div className="flex items-center justify-center h-full">
                      <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                    </div>
                  </TabsContent>
                </Tabs>
              )}
            </div>
          </ResizablePanel>
        </ResizablePanelGroup>
      </main>
    </div>
  );
}

export const LayersIcon = (props: any) => {
  return (
    <svg
      {...props}
      className="flex-shrink-0 inline-block h-full select-none fill-custom-darkblue"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      focusable="false"
    >
      <g>
        <path d="M220,169.09l-92,53.65L36,169.09A8,8,0,0,0,28,182.91l96,56a8,8,0,0,0,8.06,0l96-56A8,8,0,1,0,220,169.09Z"></path>
        <path d="M220,121.09l-92,53.65L36,121.09A8,8,0,0,0,28,134.91l96,56a8,8,0,0,0,8.06,0l96-56A8,8,0,1,0,220,121.09Z"></path>
        <path d="M28,86.91l96,56a8,8,0,0,0,8.06,0l96-56a8,8,0,0,0,0-13.82l-96-56a8,8,0,0,0-8.06,0l-96,56a8,8,0,0,0,0,13.82Z"></path>
      </g>
    </svg>
  );
};

export const PlusIcon = (props: any) => {
  return (
    <svg
      {...props}
      className="flex-shrink-0 inline-block h-full select-none fill-custom-darkblue"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      focusable="false"
    >
      <g>
        <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM184,136H136v48a8,8,0,0,1-16,0V136H72a8,8,0,0,1,0-16h48V72a8,8,0,0,1,16,0v48h48a8,8,0,0,1,0,16Z"></path>
      </g>
    </svg>
  );
};

export const MinusIcon = (props: any) => {
  return (
    <svg
      {...props}
      className="flex-shrink-0 inline-block h-full select-none fill-custom-darkblue"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      focusable="false"
    >
      <g>
        <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM184,136H72a8,8,0,0,1,0-16H184a8,8,0,0,1,0,16Z"></path>
      </g>
    </svg>
  );
};
