import { zodResolver } from "@hookform/resolvers/zod";
import { fetchAuthSession } from "aws-amplify/auth";
import axios, { AxiosError } from "axios";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { MyContext } from "../App";
import NinoxClient, { Database, Team } from "../ninox-client";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogClose,
} from "./ui/dialog";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { RadioGroup, RadioGroupItem } from "./ui/radio-group";
import { LayersIcon, MinusIcon, PlusIcon } from "../pages/ArcBotApp/ArcBotApp";
import { NinoxEnvironment } from "../types";
import useConfirm from "../utils/useConfirm";

const formSchema = z.object({
  cloudType: z.enum(["public", "private"]),
  ninoxPrivateDomain: z.string(),
  apiKey: z.string().min(1, "API Key is required"),
});

const NewEnvironmentDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    apiClient,
    // databaseConnected,
    setDatabaseConnected,
    setLoginOpen,
    setNinoxTables,
    setGlobalCode,
    signedInUser,
    setNinoxDatabaseName,
    ninoxDatabaseName,
    setLoading,
  } = useContext(MyContext);

  const { getConfirmation, Confirmation } = useConfirm();

  const [isAddNinoxServer, setIsAddNinoxServer] = useState(false);
  // const [privateCloudDomain, setPrivateCloudDomain] = useState<string>();
  // const [apiKey, setApiKey] = useState<string>();
  // const [selectedNinoxEnvironment, setSelectedNinoxEnvironment] =
  //   useState<number>();
  const [teams, setTeams] = useState<Team[]>();
  const [databases, setDatabases] = useState<Database[]>();
  const [activeUserEnvironment, setActiveUserEnvironment] =
    useState<NinoxEnvironment>();
  const [selectedCloudUrl, setSelectedCloudUrl] = useState<string>();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      cloudType: "public",
      ninoxPrivateDomain: "",
      apiKey: "",
    },
  });

  useEffect(() => {
    void (async () => {
      if (signedInUser?.selectedUserEnvironmentId) {
        const activeEnv = signedInUser.ninoxEnvironments.find(
          (e) =>
            e.environmentId.toString() ===
            signedInUser.selectedUserEnvironmentId
        );
        if (activeEnv) {
          const client = new NinoxClient(activeEnv.cloudUrl, activeEnv.apiKey);

          try {
            const envTeams = await client.getTeams();
            setTeams(envTeams);
            try {
              client.setTeamId(activeEnv.teamId);
              const envDatabases = await client.getDatabases();
              setDatabases(envDatabases);

              try {
                client.setDatabaseId(activeEnv.databaseId);
                const ninoxDatabase = await client.getDatabase();
                const ninoxTablesImported = ninoxDatabase.schema.types;
                setNinoxTables(ninoxTablesImported);
                setGlobalCode(ninoxDatabase.schema.globalCode);
                setNinoxDatabaseName(ninoxDatabase.settings.name);
                setDatabaseConnected(true);
              } catch (err) {
                alert("ninox env invalid for database");
              }
            } catch (err) {
              alert("ninox env invalid for databases");
            }
          } catch (err) {
            alert("ninox env invalid for teams");
          }
        }
        setActiveUserEnvironment(activeEnv);
        setSelectedCloudUrl(activeEnv?.cloudUrl);
        setSelectedTeamId(activeEnv?.teamId);
      }
    })();
  }, [signedInUser]);

  const groupedEnvironments = signedInUser?.ninoxEnvironments.reduce(
    (acc, env) => {
      if (!acc[env.cloudUrl]) {
        acc[env.cloudUrl] = [];
      }
      acc[env.cloudUrl].push(env);
      return acc;
    },
    {} as Record<string, NinoxEnvironment[]>
  );

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    console.log(values);
    setLoading(true);
    try {
      const getCloudUrl = () =>
        values.cloudType === "public"
          ? `https://api.ninox.com`
          : `https://${values.ninoxPrivateDomain}.ninoxdb.de`;

      const ninoxClient = new NinoxClient(getCloudUrl(), values.apiKey);
      try {
        const envTeams = await ninoxClient.getTeams();
        alert(JSON.stringify(teams));

        const firstTeam = envTeams[0];
        ninoxClient.setTeamId(firstTeam.id);

        const firstDatabase = await ninoxClient.getDatabases();

        await apiClient.createNinoxEnvironment({
          cloudUrl: getCloudUrl(),
          apiKey: values.apiKey,
          teamId: firstTeam.id,
          databaseId: firstDatabase[0].id,
        });

        await fetchAuthSession({ forceRefresh: true });

        setIsAddNinoxServer(false);
      } catch (err) {
        console.error(err);
        if (axios.isAxiosError(err)) {
          const axiosError = err as AxiosError;
          alert(`Ninox server invalid! ${JSON.stringify(axiosError.cause)}`);
        } else {
          alert("Ninox server invalid!");
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        setIsAddNinoxServer(false);
        onClose();
      }}
    >
      <DialogContent>
        {/* <DialogTitle>New Environment</DialogTitle> */}
        <DialogDescription>
          <div className="flex p-4 space-x-8 min-w-80">
            <div className="w-1/3 pr-2 space-y-4 border-r-2">
              <Button
                variant="outline"
                className="justify-start w-full gap-2 mb-4 border-none text-custom-darkblue"
                onClick={() => setIsAddNinoxServer(true)}
              >
                <PlusIcon />
                Add Ninox Server
              </Button>

              {groupedEnvironments &&
                Object.entries(groupedEnvironments).map(
                  ([cloudUrl, environments]) => (
                    <Button
                      variant="outline"
                      className={`justify-start w-full gap-2 mb-4 border-none text-custom-darkblue ${selectedCloudUrl === cloudUrl ? "bg-blue-50" : ""}`}
                      onClick={async () => {
                        setIsAddNinoxServer(false);
                        setLoading(true);
                        try {
                          const client = new NinoxClient(
                            cloudUrl,
                            environments[0].apiKey
                          );
                          const selectedTeams = await client.getTeams();
                          setSelectedCloudUrl(cloudUrl);
                          setTeams(selectedTeams);
                          setDatabases([]);
                          // client.setTeamId(selectedTeams[0].id);
                          // const selectedDatabases =
                          //   await client.getDatabases();
                          // await apiClient.editNinoxEnvironment({
                          //   ...activeUserEnvironment,
                          //   environmentId: Number(
                          //     environments[0].environmentId
                          //   ),
                          //   cloudUrl: cloudUrl,
                          //   teamId: selectedTeams[0].id,
                          //   databaseId: selectedDatabases[0].id,
                          //   apiKey: environments[0].apiKey,
                          // });

                          // await apiClient.selectNinoxEnvironment({
                          //   environmentId: Number(
                          //     environments[0].environmentId
                          //   ),
                          // });
                          // const selectedEnv =
                          //   signedInUser?.ninoxEnvironments.find(
                          //     (e) =>
                          //       e.environmentId ===
                          //       environments[0].environmentId
                          //   );
                          // setActiveUserEnvironment(selectedEnv);
                          // await fetchAuthSession({ forceRefresh: true });
                        } finally {
                          setLoading(false);
                        }
                      }}
                    >
                      <LayersIcon />
                      <div className="flex flex-col items-start">
                        <div>{`${environments[0].cloudUrl}`}</div>
                        {activeUserEnvironment?.cloudUrl === cloudUrl && (
                          <div className="text-[8px] text-gray-500">active</div>
                        )}
                      </div>
                    </Button>
                  )
                )}
            </div>
            {!isAddNinoxServer &&
              selectedCloudUrl &&
              groupedEnvironments?.[selectedCloudUrl][0] &&
              teams &&
              databases && (
                <div className="flex gap-4">
                  <div className="w-1/3 pr-2 space-y-4 overflow-y-auto border-r-2 max-h-96">
                    {teams.map((team) => {
                      const isTeamInEnvironment = groupedEnvironments?.[
                        selectedCloudUrl
                      ]
                        .map((e) => e.teamId)
                        .includes(team.id);
                      return (
                        <Button
                          key={team.id}
                          variant="outline"
                          className={`justify-start w-full gap-2 mb-4 border-none ${isTeamInEnvironment ? "text-custom-darkblue" : "text-gray-500"} ${selectedTeamId === team.id ? "bg-blue-50" : ""}`}
                          onClick={async () => {
                            setLoading(true);
                            try {
                              const client = new NinoxClient(
                                selectedCloudUrl,
                                groupedEnvironments?.[
                                  selectedCloudUrl
                                ][0].apiKey
                              );
                              client.setTeamId(team.id);
                              const selectedDatabases =
                                await client.getDatabases();

                              setDatabases(selectedDatabases);

                              setSelectedTeamId(team.id);

                              // await apiClient.editNinoxEnvironment({
                              //   ...activeUserEnvironment,
                              //   teamId: team.id,
                              //   databaseId: selectedDatabases[0].id,
                              // });
                              // await fetchAuthSession({ forceRefresh: true });
                            } finally {
                              setLoading(false);
                            }
                          }}
                        >
                          <LayersIcon />

                          <div className="flex flex-col items-start">
                            <div>{`${team.name}`}</div>
                            {activeUserEnvironment?.teamId === team.id && (
                              <div className="text-[8px] text-gray-500">
                                active
                              </div>
                            )}
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                  {selectedTeamId && (
                    <div className="w-full space-y-4 overflow-y-auto max-h-96 ">
                      {databases.map((database) => {
                        const isDatabaseInEnvironment = groupedEnvironments?.[
                          selectedCloudUrl
                        ]
                          .map((e) => e.databaseId)
                          .includes(database.id);
                        return (
                          <div key={database.id} className="flex items-center">
                            <Confirmation />
                            <Button
                              variant="outline"
                              className={`justify-start w-full max-w-56 gap-2 mb-4 border-none ${isDatabaseInEnvironment ? "text-custom-darkblue" : "text-gray-500"} ${activeUserEnvironment?.databaseId === database.id ? "bg-blue-50" : ""}`}
                              onClick={async () => {
                                const confirm = await getConfirmation({
                                  title: "Attention!",
                                  message: `Do you want to make the database: ${database.name} - ${database.id} active?`,
                                });
                                if (!confirm) {
                                  return;
                                }
                                const existingEnv =
                                  signedInUser?.ninoxEnvironments.find(
                                    (e) => e.databaseId === database.id
                                  );
                                // if database not exist we create a new environment
                                if (!existingEnv) {
                                  setLoading(true);
                                  try {
                                    await apiClient.createNinoxEnvironment({
                                      cloudUrl: selectedCloudUrl,
                                      apiKey:
                                        groupedEnvironments?.[
                                          selectedCloudUrl
                                        ][0].apiKey,
                                      teamId: selectedTeamId,
                                      databaseId: database.id,
                                    });
                                    await fetchAuthSession({
                                      forceRefresh: true,
                                    });
                                  } finally {
                                    setLoading(false);
                                  }
                                } else {
                                  // select ninox environment
                                  setLoading(true);
                                  try {
                                    await apiClient.selectNinoxEnvironment({
                                      environmentId: existingEnv.environmentId,
                                    });
                                    await fetchAuthSession({
                                      forceRefresh: true,
                                    });
                                  } finally {
                                    setLoading(false);
                                  }
                                }
                              }}
                            >
                              <LayersIcon />
                              <div className="flex flex-col items-start">
                                <div>{`${database.name}`}</div>
                                {activeUserEnvironment?.databaseId ===
                                  database.id && (
                                  <div className="text-[8px] text-gray-500">
                                    active
                                  </div>
                                )}
                              </div>
                            </Button>
                            {isDatabaseInEnvironment && (
                              <Button
                                variant="outline"
                                className="justify-start gap-2 mb-4 border-none text-custom-darkblue"
                                onClick={async () => {
                                  setLoading(true);
                                  try {
                                    const envForDelete =
                                      signedInUser?.ninoxEnvironments.find(
                                        (env) => env.databaseId === database.id
                                      );

                                    if (envForDelete) {
                                      await apiClient.removeNinoxEnvironment({
                                        environmentId:
                                          envForDelete.environmentId,
                                      });
                                      await fetchAuthSession({
                                        forceRefresh: true,
                                      });
                                    }

                                    // alert("delete env");
                                  } finally {
                                    setLoading(false);
                                  }
                                }}
                              >
                                <MinusIcon />
                              </Button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            {isAddNinoxServer && (
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-6"
                >
                  <h2 className="text-2xl font-bold">Add Ninox Server</h2>

                  <div>
                    <h3 className="mb-2 text-lg font-medium">
                      Your Ninox Cloud Type
                    </h3>
                    <FormField
                      control={form.control}
                      name="cloudType"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <RadioGroup
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                              className="flex space-x-4"
                            >
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem value="public" id="public" />
                                <label htmlFor="public">Public Cloud</label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem value="private" id="private" />
                                <label htmlFor="private">Private Cloud</label>
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  {form.getValues("cloudType") === "private" && (
                    <div>
                      <h3 className="mb-2 text-lg font-medium">
                        Your Server URL
                      </h3>
                      <FormField
                        control={form.control}
                        name="ninoxPrivateDomain"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="flex">
                                <span className="inline-flex items-center px-3 text-sm text-gray-500 border border-r-0 border-gray-300 rounded-l-md bg-gray-50">
                                  https://
                                </span>
                                <Input
                                  {...field}
                                  className="rounded focus:ring-offset-0 focus:ring-1 focus:ring-inset"
                                  placeholder="sub-domain"
                                />
                                <span className="inline-flex items-center px-3 text-sm text-gray-500 border border-l-0 border-gray-300 rounded-r-md bg-gray-50">
                                  .ninoxdb.de
                                </span>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}

                  <div>
                    <h3 className="mb-2 text-lg font-medium">Ninox API Key</h3>
                    <FormField
                      control={form.control}
                      name="apiKey"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="relative">
                              <Input
                                type={showPassword ? "text" : "password"}
                                {...field}
                              />
                              <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center pr-3"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <EyeOffIcon className="w-5 h-5 text-gray-400" />
                                ) : (
                                  <EyeIcon className="w-5 h-5 text-gray-400" />
                                )}
                              </button>
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className="flex justify-between">
                    <Button
                      variant="outline"
                      type="button"
                      onClick={() => setIsAddNinoxServer(false)}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Add Server</Button>
                  </div>
                </form>
              </Form>
            )}
          </div>
        </DialogDescription>
        <DialogClose asChild>
          <button>Close</button>
        </DialogClose>
      </DialogContent>
    </Dialog>
  );
};

export default NewEnvironmentDialog;
