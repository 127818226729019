import { z } from "zod";

export const NinoxFieldSchema = z.strictObject({
  base: z.enum([
    "string",
    "boolean",
    "rev",
    "ref",
    "date",
    "number",
    "choice",
    "time",
    "html",
    "fn",
    "link",
    "file",
    "files",
    "dchoice",
    "color",
  ]),
  numberFormat: z.string().optional(),
  caption: z.string(),
  captions: z.record(z.string()).optional(),
  required: z.boolean().optional(),
  order: z.number().optional(),
  formWidth: z.number().optional(),
  uuid: z.string().optional(),
  globalSearch: z.boolean().optional(),
  hasIndex: z.boolean().optional(),
  tooltips: z.record(z.string()).optional(),
  refTypeId: z.string().optional(),
  refFieldId: z.string().optional(),
  cascade: z.boolean().optional(),
  dchoiceValues: z.string().optional(),
  dchoiceCaption: z.string().optional(),
  dchoiceColor: z.string().optional(),
  tooltip: z.string().optional(),
  binding: z.string().optional(),
  numberDefault: z.number().optional(),
  viewConfig: z
    .strictObject({
      mode: z.string().optional(),
      type: z.string().optional(),
      sort: z.number().optional(),
      descending: z.boolean().optional(),
      group: z.number().optional(),
      cols: z
        .array(
          z.object({
            binding: z.string().optional(),
            numberDefault: z.number().optional(),
          })
        )
        .optional(),
    })
    .optional(),
  referenceRenderer: z.string().optional(),
  dateCalendar: z.boolean().optional(),
  dateYearly: z.boolean().optional(),
  booleanRenderer: z.string().optional(),
  stringAutocorrect: z.boolean().optional(),
  stringMultiline: z.boolean().optional(),
  height: z.number().optional(),
  nextChoiceId: z.number().optional(),
  style: z.string().optional(),
  linkPreview: z.boolean().optional(),
  fileHandling: z.string().optional(),
  bgSize: z.string().optional(),
  values: z
    .record(
      z.strictObject({
        caption: z.string().optional(),
        captions: z.record(z.string()).optional(),
        order: z.number().optional(),
      })
    )
    .optional(),
  choiceRenderer: z.string().optional(),
  timeFormat: z.string().optional(),
  reverseRenderer: z.string().optional(),
  width: z.number().optional(),
  fn: z.string().optional(),
  visibility: z.string().optional(),
  afterUpdate: z.string().optional(),
  labelPosition: z.string().optional(),
});

export type NinoxField = z.infer<typeof NinoxFieldSchema>;

export const NinoxTableSchema = z.strictObject({
  nextFieldId: z.number(),
  caption: z.string(),
  captions: z.record(z.string()).optional(),
  hidden: z.boolean().optional(),
  hasFiles: z.boolean().optional(),
  hasComments: z.boolean().optional(),
  hasHistory: z.boolean().optional(),
  icon: z.string().optional(),
  fields: z.record(NinoxFieldSchema).optional(),
  uis: z.record(z.unknown()).optional(),
  uuid: z.string().optional(),
  globalSearch: z.boolean().optional(),
  order: z.number().optional(),
  kind: z.enum(["page", "table"]).optional(),
  afterCreate: z.string().optional(),
});

// table or field can be null when deleting
export const NinoxTableSchemaUpdate = z
  .strictObject({
    nextFieldId: z.number(),
    caption: z.string(),
    captions: z.record(z.string()).optional(),
    hidden: z.boolean().optional(),
    hasFiles: z.boolean().optional(),
    hasComments: z.boolean().optional(),
    hasHistory: z.boolean().optional(),
    icon: z.string().optional(),
    fields: z.record(NinoxFieldSchema.nullable()).optional(),
    uis: z.record(z.unknown()).optional(),
    uuid: z.string().optional(),
    globalSearch: z.boolean().optional(),
    order: z.number().optional(),
    kind: z.enum(["page", "table"]).optional(),
    afterCreate: z.string().optional(),
  })
  .nullable();

export type NinoxTable = z.infer<typeof NinoxTableSchema>;
export type NinoxTableUpdate = z.infer<typeof NinoxTableSchemaUpdate>;

export const NinoxDatabaseSchemaZod = z.strictObject({
  seq: z.number().optional(),
  version: z.number().optional(),
  nextTypeId: z.number().optional(),
  types: z.record(NinoxTableSchema),
  afterOpenBehavior: z.string().optional(),
  afterOpen: z.string().optional(),
  fileSync: z.string().optional(),
  globalCode: z.string().optional(),
  dateFix: z.string().optional(),
  compatibility: z.string().optional(),
  hideCalendar: z.boolean().optional(),
  hideSearch: z.boolean().optional(),
  hideDatabase: z.boolean().optional(),
  hideNavigation: z.boolean().optional(),
  knownDatabases: z.array(z.unknown()).optional(),
});

export type NinoxDatabaseSchema = z.infer<typeof NinoxDatabaseSchemaZod>;

export const NinoxDatabaseSettingsZodSchema = z.strictObject({
  name: z.string(),
  icon: z.string().optional(),
  color: z.string().optional(),
});

export const NinoxDatabaseZod = z.strictObject({
  dbname: z.string().optional(),
  name: z.string().optional(),
  id: z.string().optional(),
  seq: z.number().optional(),
  settings: NinoxDatabaseSettingsZodSchema,
  schema: NinoxDatabaseSchemaZod,
});

export type NinoxDatabase = z.infer<typeof NinoxDatabaseZod>;
