const PrivacyPolicyDE = () => {
  return (
    <div>
      <h2 id="m4158">Präambel</h2>
      <p>
        Mit den folgenden Bedingungen möchten wir Sie darüber aufklären, welche
        Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als "Daten"
        bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen der
        Bereitstellung unserer Applikation verarbeiten und welche Haftung
        vorliegt.
      </p>
      <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
      <p>Stand: 14. Januar 2024</p>
      <h2>Inhaltsübersicht</h2>{" "}
      <ul className="list-disc pl-14 index">
        <li>
          <a className="index-link" href="#m4158">
            Präambel
          </a>
        </li>
        <li>
          <a className="index-link" href="#m3">
            Verantwortlicher
          </a>
        </li>
        <li>
          <a className="index-link" href="#mOverview">
            Übersicht der Verarbeitungen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m2427">
            Maßgebliche Rechtsgrundlagen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m27">
            Sicherheitsmaßnahmen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m25">
            Übermittlung von personenbezogenen Daten
          </a>
        </li>
        <li>
          <a className="index-link" href="#m24">
            Internationale Datentransfers
          </a>
        </li>
        <li>
          <a className="index-link" href="#m12">
            Löschung von Daten
          </a>
        </li>
        <li>
          <a className="index-link" href="#m10">
            Rechte der betroffenen Personen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m134">
            Einsatz von Cookies
          </a>
        </li>
        <li>
          <a className="index-link" href="#m225">
            Bereitstellung des Onlineangebotes und Webhosting
          </a>
        </li>
        <li>
          <a className="index-link" href="#m367">
            Registrierung, Anmeldung und Nutzerkonto
          </a>
        </li>
        <li>
          <a className="index-link" href="#m432">
            Community Funktionen
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Kontakt- und Anfragenverwaltung
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Speicherung der Daten in Ninox
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Haftung
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            AWS S3
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Speicherung der Daten in AWS Cognito
          </a>
        </li>
        <li>
          <a className="index-link" href="#m15">
            Änderung und Aktualisierung der Datenschutzerklärung
          </a>
        </li>
      </ul>
      <h2 id="m3">Verantwortlicher</h2>
      <p>
        Jakob Jordan
        <br />
        Jänickendorferstr. 64A
        <br />
        14943 Luckenwalde
      </p>
      E-Mail-Adresse:{" "}
      <a href="mailto:jakob.jordan@arbot.de">jakob.jordan@arbot.de</a>
      Impressum: www.arbot.de/impressum
      <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
      <p>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
        die Zwecke ihrer Verarbeitung zusammen und verweist auf die betroffenen
        Personen.
      </p>
      <h3>Arten der verarbeiteten Daten</h3>
      <ul>
        <li>Bestandsdaten.</li>
        <li>Kontaktdaten.</li>
        <li>Inhaltsdaten.</li>
        <li>Nutzungsdaten.</li>
        <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
      </ul>
      <h3>Kategorien betroffener Personen</h3>
      <ul>
        <li>Kommunikationspartner.</li>
        <li>Nutzer.</li>
      </ul>
      <h3>Zwecke der Verarbeitung</h3>
      <ul>
        <li>
          Erbringung vertraglicher Leistungen und Erfüllung vertraglicher
          Pflichten.
        </li>
        <li>Kontaktanfragen und Kommunikation.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Verwaltung und Beantwortung von Anfragen.</li>
        <li>Feedback.</li>
        <li>
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li>Informationstechnische Infrastruktur.</li>
      </ul>
      <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
      <p>
        <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
        Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
        auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen Sie
        zur Kenntnis, dass neben den Regelungen der DSGVO nationale
        Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
        können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
        maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
      </p>
      <ul>
        <li>
          <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> - Die
          betroffene Person hat ihre Einwilligung in die Verarbeitung der sie
          betreffenden personenbezogenen Daten für einen spezifischen Zweck oder
          mehrere bestimmte Zwecke gegeben.
        </li>
        <li>
          <strong>
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
            lit. b) DSGVO)
          </strong>{" "}
          - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
          Vertragspartei die betroffene Person ist, oder zur Durchführung
          vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
          betroffenen Person erfolgen.
        </li>
        <li>
          <strong>
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
          </strong>{" "}
          - Die Verarbeitung ist zur Wahrung der berechtigten Interessen des
          Verantwortlichen oder eines Dritten erforderlich, sofern nicht die
          Interessen oder Grundrechte und Grundfreiheiten der betroffenen
          Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        </li>
      </ul>
      <p>
        <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
        Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
        Regelungen zum Datenschutz in Deutschland. Hierzu gehört insbesondere
        das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der
        Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält
        insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf
        Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien
        personenbezogener Daten, zur Verarbeitung für andere Zwecke und zur
        Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Ferner können Landesdatenschutzgesetze der
        einzelnen Bundesländer zur Anwendung gelangen.
      </p>
      <p>
        <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
        Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
        schweizerischen Bundesgesetz über den Datenschutz (Schweizer DSG) als
        auch nach der Datenschutzgrundverordnung (DSGVO). Aus diesem Grund
        bitten wir Sie zu beachten, dass aufgrund der breiteren räumlichen
        Anwendung und Verständlichkeit die Begriffe der DSGVO verwendet werden.
        Insbesondere statt der im Schweizer DSG verwendeten Begriffe
        „Bearbeitung" von „Personendaten", "überwiegendes Interesse" und
        "besonders schützenswerte Personendaten" werden die in der DSGVO
        verwendeten Begriffe „Verarbeitung" von „personenbezogenen Daten" sowie
        "berechtigtes Interesse" und "besondere Kategorien von Daten" verwendet.
        Die gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
        des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
      </p>
      <h2 id="m27">Sicherheitsmaßnahmen</h2>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
        Berücksichtigung des Stands der Technik, der Implementierungskosten und
        der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie
        der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
        Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete
        technische und organisatorische Maßnahmen, um ein dem Risiko
        angemessenes Schutzniveau zu gewährleisten.
      </p>
      <p>
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit,
        Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
        und elektronischen Zugangs zu den Daten als auch des sie betreffenden
        Zugriffs, der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit
        und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die
        eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
        Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
        berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
        Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren
        entsprechend dem Prinzip des Datenschutzes, durch Technikgestaltung und
        durch datenschutzfreundliche Voreinstellungen.
      </p>
      <p>
        TLS/SSL-Verschlüsselung (https): Um die Daten der Benutzer, die über
        unsere Online-Dienste übertragen werden, zu schützen, verwenden wir
        TLS/SSL-Verschlüsselung. Secure Sockets Layer (SSL) ist die
        Standardtechnologie zur Sicherung von Internetverbindungen durch
        Verschlüsselung der zwischen einer Website oder App und einem Browser
        (oder zwischen zwei Servern) übertragenen Daten. Transport Layer
        Security (TLS) ist eine aktualisierte und sicherere Version von SSL.
        Hyper Text Transfer Protocol Secure (HTTPS) wird in der URL angezeigt,
        wenn eine Website durch ein SSL/TLS-Zertifikat gesichert ist.
      </p>
      <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
      <p>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor,
        dass die Daten an andere Stellen, Unternehmen, rechtlich selbstständige
        Organisationseinheiten oder Personen übermittelt oder sie ihnen
        gegenüber offengelegt werden. Zu den Empfängern dieser Daten können
        z. B. mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von
        Diensten und Inhalten, die in eine Webseite eingebunden werden, gehören.
        In solchen Fällen beachten wir die gesetzlichen Vorgaben und schließen
        insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz
        Ihrer Daten dienen, mit den Empfängern Ihrer Daten ab.
      </p>
      <h2 id="m24">Internationale Datentransfers</h2>
      <p>
        Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
        (d. h., außerhalb der Europäischen Union (EU), des Europäischen
        Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der
        Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
        Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
        stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
        Sofern das Datenschutzniveau in dem Drittland mittels eines
        Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient dieser
        als Grundlage des Datentransfers. Im Übrigen erfolgen Datentransfers nur
        dann, wenn das Datenschutzniveau anderweitig gesichert ist, insbesondere
        durch Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
        ausdrückliche Einwilligung oder im Fall vertraglicher oder gesetzlich
        erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im Übrigen teilen
        wir Ihnen die Grundlagen der Drittlandübermittlung bei den einzelnen
        Anbietern aus dem Drittland mit, wobei die Angemessenheitsbeschlüsse als
        Grundlagen vorrangig gelten. Informationen zu Drittlandtransfers und
        vorliegenden Angemessenheitsbeschlüssen können dem Informationsangebot
        der EU-Kommission entnommen werden:{" "}
        <a
          href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
          target="_blank"
        >
          https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
        </a>
      </p>
      <p>
        EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
        „Data Privacy Framework" (DPF) hat die EU-Kommission das
        Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
        Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
        anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
        Informationen zu dem DPF können Sie der Webseite des Handelsministeriums
        der USA unter{" "}
        <a href="https://www.dataprivacyframework.gov/" target="_blank">
          https://www.dataprivacyframework.gov/
        </a>{" "}
        (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
        Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter
        dem Data Privacy Framework zertifiziert sind.
      </p>
      <h2 id="m12">Löschung von Daten</h2>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht, sobald deren zur Verarbeitung erlaubten
        Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen
        (z. B. wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder
        sie für den Zweck nicht erforderlich sind). Sofern die Daten nicht
        gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
        erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt.
        D. h., die Daten werden gesperrt und nicht für andere Zwecke
        verarbeitet. Das gilt z. B. für Daten, die aus handels- oder
        steuerrechtlichen Gründen aufbewahrt werden müssen oder deren
        Speicherung zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen
        oder juristischen Person erforderlich ist. Unsere Datenschutzhinweise
        können ferner weitere Angaben zu der Aufbewahrung und Löschung von Daten
        beinhalten, die für die jeweiligen Verarbeitungen vorrangig gelten.
      </p>
      <h2 id="m10">Rechte der betroffenen Personen</h2>
      <p>
        Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
        Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere
        aus Art. 15 bis 21 DSGVO ergeben:
      </p>
      <ul>
        <li>
          <strong>
            Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
            Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung
            der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.
            6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies
            gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
            Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit
            Widerspruch gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen;
            dies gilt auch für das Profiling, soweit es mit solcher
            Direktwerbung in Verbindung steht.
          </strong>
        </li>
        <li>
          <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
          Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        </li>
        <li>
          <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung
          darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
          Auskunft über diese Daten sowie auf weitere Informationen und Kopie
          der Daten entsprechend den gesetzlichen Vorgaben.
        </li>
        <li>
          <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
          gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
          betreffenden Daten oder die Berichtigung der Sie betreffenden
          unrichtigen Daten zu verlangen.
        </li>
        <li>
          <strong>
            Recht auf Löschung und Einschränkung der Verarbeitung:
          </strong>{" "}
          Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
          verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
          bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
          Einschränkung der Verarbeitung der Daten zu verlangen.
        </li>
        <li>
          <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht,
          Sie betreffende Daten, die Sie uns bereitgestellt haben, nach Maßgabe
          der gesetzlichen Vorgaben in einem strukturierten, gängigen und
          maschinenlesbaren Format zu erhalten oder deren Übermittlung an einen
          anderen Verantwortlichen zu fordern.
        </li>
        <li>
          <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
          unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen
          Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
          betreffenden personenbezogenen Daten gegen die Vorgaben der DSGVO
          verstößt.
        </li>
      </ul>
      <h2 id="m134">Einsatz von Cookies</h2>
      <p>
        Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
        Informationen auf Endgeräten speichern und Informationen aus den
        Endgeräten auslesen. Z. B. um den Login-Status in einem Nutzerkonto,
        einen Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
        verwendete Funktionen eines Onlineangebotes speichern. Cookies können
        ferner zu unterschiedlichen Zwecken eingesetzt werden, z. B. zu Zwecken
        der Funktionsfähigkeit, Sicherheit und Komfort von Onlineangeboten sowie
        der Erstellung von Analysen der Besucherströme.{" "}
      </p>
      <p>
        <strong>Hinweise zur Einwilligung: </strong>Wir setzen Cookies im
        Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von den
        Nutzern eine vorhergehende Einwilligung ein, außer wenn diese gesetzlich
        nicht gefordert ist. Eine Einwilligung ist insbesondere nicht notwendig,
        wenn das Speichern und das Auslesen der Informationen, also auch von
        Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von ihnen
        ausdrücklich gewünschten Telemediendienst (also unser Onlineangebot) zur
        Verfügung zu stellen. Zu den unbedingt erforderlichen Cookies gehören in
        der Regel Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des
        Onlineangebotes , dem Lastausgleich, der Sicherheit, der Speicherung der
        Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
        Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
        angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
        widerrufliche Einwilligung wird gegenüber den Nutzern deutlich
        kommuniziert und enthält die Informationen zu der jeweiligen
        Cookie-Nutzung.
      </p>
      <p>
        <strong>Hinweise zu datenschutzrechtlichen Rechtsgrundlagen: </strong>
        Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die
        personenbezogenen Daten der Nutzer mit Hilfe von Cookies verarbeiten,
        hängt davon ab, ob wir Nutzer um eine Einwilligung bitten. Falls die
        Nutzer einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten
        die erklärte Einwilligung. Andernfalls werden die mithilfe von Cookies
        verarbeiteten Daten auf Grundlage unserer berechtigten Interessen (z. B.
        an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und
        Verbesserung seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen
        der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der Einsatz
        von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu
        erfüllen. Zu welchen Zwecken die Cookies von uns verarbeitet werden,
        darüber klären wir im Laufe dieser Datenschutzerklärung oder im Rahmen
        von unseren Einwilligungs- und Verarbeitungsprozessen auf.
      </p>
      <p>
        <strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer werden
        die folgenden Arten von Cookies unterschieden:
      </p>
      <ul>
        <li>
          <strong>
            Temporäre Cookies (auch: Session- oder Sitzungs-Cookies):
          </strong>
           Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
          Online-Angebot verlassen und sein Endgerät (z. B. Browser oder mobile
          Applikation) geschlossen hat.
        </li>
        <li>
          <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
          nach dem Schließen des Endgerätes gespeichert. So können
          beispielsweise der Login-Status gespeichert oder bevorzugte Inhalte
          direkt angezeigt werden, wenn der Nutzer eine Website erneut besucht.
          Ebenso können die mit Hilfe von Cookies erhobenen Daten der Nutzer zur
          Reichweitenmessung verwendet werden. Sofern wir Nutzern keine
          expliziten Angaben zur Art und Speicherdauer von Cookies mitteilen
          (z. B. im Rahmen der Einholung der Einwilligung), sollten Nutzer davon
          ausgehen, dass Cookies permanent sind und die Speicherdauer bis zu
          zwei Jahre betragen kann.
        </li>
      </ul>
      <p>
        <strong>
          Allgemeine Hinweise zum Widerruf und Widerspruch (sog. "Opt-Out"):{" "}
        </strong>
        Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
        widerrufen und der Verarbeitung entsprechend den gesetzlichen Vorgaben
        widersprechen. Hierzu können Nutzer unter anderem die Verwendung von
        Cookies in den Einstellungen ihres Browsers einschränken (wobei dadurch
        auch die Funktionalität unseres Onlineangebotes eingeschränkt sein
        kann). Ein Widerspruch gegen die Verwendung von Cookies zu
        Online-Marketing-Zwecken kann auch über die Websites{" "}
        <a href="https://optout.aboutads.info/" target="_new">
          https://optout.aboutads.info
        </a>{" "}
        und{" "}
        <a href="https://www.youronlinechoices.com/" target="_new">
          https://www.youronlinechoices.com/
        </a>{" "}
        erklärt werden.
      </p>
      <ul className="m-elements">
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
          DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>
            Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
          </strong>
          Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in
          dessen Rahmen die Einwilligungen der Nutzer in den Einsatz von
          Cookies, bzw. der im Rahmen des
          Cookie-Einwilligungs-Management-Verfahrens genannten Verarbeitungen
          und Anbieter eingeholt sowie von den Nutzern verwaltet und widerrufen
          werden können. Hierbei wird die Einwilligungserklärung gespeichert, um
          deren Abfrage nicht erneut wiederholen zu müssen und die Einwilligung
          entsprechend der gesetzlichen Verpflichtung nachweisen zu können. Die
          Speicherung kann serverseitig und/oder in einem Cookie (sogenanntes
          Opt-In-Cookie, bzw. mithilfe vergleichbarer Technologien) erfolgen, um
          die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
          Vorbehaltlich individueller Angaben zu den Anbietern von
          Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer
          der Speicherung der Einwilligung kann bis zu zwei Jahren betragen.
          Hierbei wird ein pseudonymer Nutzer-Identifikator gebildet und mit dem
          Zeitpunkt der Einwilligung, Angaben zur Reichweite der Einwilligung
          (z. B. welche Kategorien von Cookies und/oder Diensteanbieter) sowie
          dem Browser, System und verwendeten Endgerät gespeichert;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a) DSGVO).
          </span>
        </li>
      </ul>
      <h2 id="m225">Bereitstellung des Onlineangebotes und Webhosting</h2>
      <p>
        Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste zur
        Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
        IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und Funktionen
        unserer Online-Dienste an den Browser oder das Endgerät der Nutzer zu
        übermitteln.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
          Kommunikations- und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. .B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
          Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
          Infrastruktur (Betrieb und Bereitstellung von Informationssystemen und
          technischen Geräten (Computer, Server etc.).). Sicherheitsmaßnahmen.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der Zugriff
          auf unser Onlineangebot wird in Form von so genannten
          "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
          Adresse und Name der abgerufenen Webseiten und Dateien, Datum und
          Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
          erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des
          Nutzers, Referrer URL (die zuvor besuchte Seite) und im Regelfall
          IP-Adressen und der anfragende Provider gehören. Die Serverlogfiles
          können zum einen zu Zwecken der Sicherheit eingesetzt werden, z. B.,
          um eine Überlastung der Server zu vermeiden (insbesondere im Fall von
          missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
          anderen, um die Auslastung der Server und ihre Stabilität
          sicherzustellen;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).{" "}
          </span>
          <strong>Löschung von Daten:</strong> Logfile-Informationen werden für
          die Dauer von maximal 30 Tagen gespeichert und danach gelöscht oder
          anonymisiert. Daten, deren weitere Aufbewahrung zu Beweiszwecken
          erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen
          Vorfalls von der Löschung ausgenommen.
        </li>
      </ul>
      <h2 id="m367">Registrierung, Anmeldung und Nutzerkonto</h2>
      <p>
        Nutzer können ein Nutzerkonto anlegen. Im Rahmen der Registrierung
        werden den Nutzern die erforderlichen Pflichtangaben mitgeteilt und zu
        Zwecken der Bereitstellung des Nutzerkontos auf Grundlage vertraglicher
        Pflichterfüllung verarbeitet. Zu den verarbeiteten Daten gehören
        insbesondere die Login-Informationen (Nutzername, Passwort sowie eine
        E-Mail-Adresse und ob ein Ninox Account vorhanden ist).
      </p>
      <p>
        Im Rahmen der Inanspruchnahme unserer Registrierungs- und
        Anmeldefunktionen sowie der Nutzung des Nutzerkontos speichern wir die
        IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung. Die
        Speicherung erfolgt auf Grundlage unserer berechtigten Interessen als
        auch jener der Nutzer an einem Schutz vor Missbrauch und sonstiger
        unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt
        grundsätzlich nicht, es sei denn, sie ist zur Verfolgung unserer
        Ansprüche erforderlich oder es besteht eine gesetzliche Verpflichtung
        hierzu.
      </p>
      <p>
        Die Nutzer können über Vorgänge, die für deren Nutzerkonto relevant
        sind, wie z. B. technische Änderungen, per E-Mail informiert werden.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. Namen,
          Adressen); Kontaktdaten (z. B. E-Mail, Telefonnummern); Inhaltsdaten
          (z. B. Eingaben in Onlineformularen); Meta-, Kommunikations- und
          Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. .B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Erfüllung vertraglicher Pflichten;
          Sicherheitsmaßnahmen; Verwaltung und Beantwortung von Anfragen.
          Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Registrierung mit Klarnamen: </strong>Aufgrund der Natur
          unserer Community bitten wir die Nutzer unser Angebot nur unter
          Verwendung von Klarnamen zu nutzen. D. h. die Nutzung von Pseudonymen
          ist nicht zulässig;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </span>
        </li>
        <li>
          <strong>Profile der Nutzer sind nicht öffentlich: </strong>Die Profile
          der Nutzer sind öffentlich nicht sichtbar und nicht zugänglich.
        </li>
        <li>
          <strong>Löschung von Daten nach Kündigung: </strong>Wenn Nutzer ihr
          Nutzerkonto gekündigt haben, werden deren Daten im Hinblick auf das
          Nutzerkonto, vorbehaltlich einer gesetzlichen Erlaubnis, Pflicht oder
          Einwilligung der Nutzer, gelöscht;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </span>
        </li>
      </ul>
      <h2 id="m432">Community Funktionen</h2>
      <p>
        Die von uns bereitgestellten Community Funktionen erlauben es Nutzern
        miteinander in Konversationen oder sonst miteinander in einen Austausch
        zu treten. Hierbei bitten wir zu beachten, dass die Nutzung der
        Communityfunktionen nur unter Beachtung der geltenden Rechtslage,
        unserer Bedingungen und Richtlinien sowie der Rechte anderer Nutzer und
        Dritter gestattet ist.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
          besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten); Meta-,
          Kommunikations- und Verfahrensdaten (z. .B. IP-Adressen, Zeitangaben,
          Identifikationsnummern, Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Nutzer (z. .B.
          Webseitenbesucher, Nutzer von Onlinediensten).
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
          Leistungen und Erfüllung vertraglicher Pflichten.
          Sicherheitsmaßnahmen.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
          vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Beiträge der Nutzer sind öffentlich: </strong>Die von Nutzern
          erstellten Beiträge und Inhalte sind öffentlich sichtbar und
          zugänglich;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </span>
        </li>
        <li>
          <strong>Speicherung von Daten zu Sicherheitszwecken: </strong>Die
          Beiträge und sonstige Eingaben der Nutzer werden zu Zwecken der
          Community- und Konversationsfunktionen verarbeitet und, vorbehaltlich
          gesetzlicher Pflichten oder gesetzlicher Erlaubnis nicht an Dritte
          herausgegeben. Eine Herausgabepflicht kann insbesondere im Fall von
          rechtswidrigen Beiträgen zu Zwecken der Rechtsverfolgung entstehen.
          Wir weisen darauf hin, dass neben den Inhalten der Beiträge auch deren
          Zeitpunkt und die IP-Adresse der Nutzer gespeichert werden. Dies
          geschieht, um zum Schutz anderer Nutzer und der Community angemessene
          Maßnahmen ergreifen zu können;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
          </span>
        </li>
      </ul>
      <h2 id="m182">Kontakt- und Anfragenverwaltung</h2>
      <p>
        Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
        E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
        Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
        Personen verarbeitet soweit dies zur Beantwortung der Kontaktanfragen
        und etwaiger angefragter Maßnahmen erforderlich ist.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Verarbeitete Datenarten:</strong> Kontaktdaten (z. B. E-Mail,
          Telefonnummern); Inhaltsdaten (z. B. Eingaben in Onlineformularen);
          Nutzungsdaten (z. B. besuchte Webseiten, Interesse an Inhalten,
          Zugriffszeiten); Meta-, Kommunikations- und Verfahrensdaten (z. .B.
          IP-Adressen, Zeitangaben, Identifikationsnummern,
          Einwilligungsstatus).
        </li>
        <li>
          <strong>Betroffene Personen:</strong> Kommunikationspartner.
        </li>
        <li>
          <strong>Zwecke der Verarbeitung:</strong> Kontaktanfragen und
          Kommunikation; Verwaltung und Beantwortung von Anfragen; Feedback
          (z. B. Sammeln von Feedback via Online-Formular). Bereitstellung
          unseres Onlineangebotes und Nutzerfreundlichkeit.
        </li>
        <li className="">
          <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6 Abs.
          1 S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche Anfragen
          (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
        </li>
      </ul>
      <p>
        <strong>
          Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Kontaktformular: </strong>Wenn Nutzer über unser
          Kontaktformular, E-Mail oder andere Kommunikationswege mit uns in
          Kontakt treten, verarbeiten wir die uns in diesem Zusammenhang
          mitgeteilten Daten zur Bearbeitung des mitgeteilten Anliegens;{" "}
          <span className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </span>
        </li>
      </ul>
      <h2 id="ninox-data-storage">Speicherung von Daten in Ninox</h2>
      <h3>Art & Umfang der Verarbeitung</h3>
      <p>
        Wir speichern verschiedene Arten von Daten in unserer Ninox-Datenbank,
        um die Funktionalität unserer Dienste sicherzustellen. Die gespeicherten
        Daten umfassen:
      </p>
      <ul>
        <li>
          User-Daten: Hierzu gehören E-Mail-Adressen, Vor- und Nachnamen,
          IP-Adressen, Passwörter (unverschlüsselt), Firmennamen und Initialen.
        </li>
        <li>
          User-Sessions: Dies beinhaltet Session-IDs zur Verwaltung von
          Benutzersitzungen.
        </li>
        <li>
          Prompt Chat-Historie: Hier werden Informationen wie Benutzereingaben,
          generierte Datenbankschemata, Tabellen-IDs, Tabellennamen, Feld-IDs,
          Feldnamen, Zusammenführung (Ja/Nein), Benutzer, Ninox-Umgebung und
          weitere gespeichert.
        </li>
        <li>
          User-Feedback: Dies umfasst Benutzereingaben, generierte
          Datenbankschemata vor und nach Änderungen, Tabellen-IDs,
          Tabellennamen, Feld-IDs, Feldnamen, Zusammenführung (Ja/Nein),
          Benutzerbewertungen, Kommentare, Gespräche, Ninox-Umgebung und mehr.
        </li>
        <li>
          Ninox-Umgebungen: Hierbei handelt es sich um Informationen wie
          Ninox-URLs, Team-IDs, Datenbank-IDs und API-Schlüssel
          (unverschlüsselt).
        </li>
        <li>
          User-Events: Dazu gehören IP-Adressen, Länderinformationen,
          Benutzereingaben, generierte Datenbankschemata vor und nach
          Änderungen, Tabellen-IDs, Tabellennamen, Feld-IDs, Feldnamen,
          Zusammenführung (Ja/Nein), Benutzerbewertungen, Kommentare, Gespräche,
          Ninox-Umgebung und mehr.
        </li>
        <li>
          User-Feature-Anfragen: Hierbei handelt es sich um Titel,
          Beschreibungen, Benutzer, Abstimmungen und mehr.
        </li>
      </ul>
      <p>
        Wir verwenden Ninox, um diese Daten sicher und effizient zu speichern
        und zu verwalten. Wir möchten betonen, dass der Schutz Ihrer Daten und
        Ihrer Privatsphäre für uns von höchster Bedeutung ist.
      </p>
      <p>
        Beim Zugriff auf und der Nutzung unserer Dienste können Ihre Daten in
        unserer Ninox-Datenbank erfasst und gespeichert werden. Wir verwenden
        diese Daten ausschließlich für die Bereitstellung und Verbesserung
        unserer Dienste sowie zur Sicherstellung ihrer Sicherheit und
        Funktionalität.
      </p>
      <p>
        Ihre Privatsphäre ist uns wichtig, und wir setzen alles daran,
        sicherzustellen, dass die Speicherung und Verarbeitung Ihrer Daten in
        Übereinstimmung mit den geltenden Datenschutzbestimmungen erfolgt.
      </p>
      <strong>Zweck und Rechtsgrundlage</strong>
      <p>
        Die Speicherung Ihrer Daten in unserer Ninox-Datenbank erfolgt auf
        Grundlage unserer berechtigten Interessen, insbesondere dem Interesse an
        einer sicheren und effizienten Bereitstellung sowie der Optimierung
        unserer Dienste gemäß Art. 6 Abs. 1 lit. f. DSGVO.
      </p>
      <strong>Speicherdauer</strong>
      <p>
        Die konkrete Speicherdauer der verarbeiteten Daten wird von uns nicht
        beeinflusst, sondern durch unsere Ninox-Datenbank bestimmt. Weitere
        Informationen zur Datenschutzpraxis von Ninox finden Sie in der
        Datenschutzerklärung von Ninox:{" "}
        <a
          href="https://ninox.com/de/datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ninox.com/de/datenschutz
        </a>
        .
      </p>
      <p>
        Wir schätzen Ihr Vertrauen in unsere Dienste und nehmen den Schutz Ihrer
        Daten sehr ernst. Wenn Sie Fragen zu unseren Datenschutzrichtlinien oder
        zur Verarbeitung Ihrer Daten haben, stehen wir Ihnen gerne zur
        Verfügung.
      </p>
      <h2 id="ninox-liability">Haftung</h2>
      <ul>
        <li>
          a. arcBot haftet unbeschränkt bei Vorsatz oder grober Fahrlässigkeit
          für Schäden aus der Verletzung des Lebens, des Körpers oder der
          Gesundheit sowie nach den Vorschriften des Produkthaftungsgesetzes.
        </li>

        <li>
          b. Eine weitergehende Haftung von arcBot besteht nicht. Insbesondere
          besteht keine Haftung von arcBot für anfängliche Mängel, soweit nicht
          die Voraussetzungen von Punkt a. vorliegen.
        </li>

        <li>
          c. Eine Haftung seitens arcBot für die Funktionsfähigkeit der App als
          solcher besteht ausdrücklich nicht. Der Service wird „wie besehen'“
          bereitgestellt, ohne Garantien. Soweit Ninox eine neue Version der
          Ninox-Software herausbringt, die die KI-Bot funktionsunfähig macht,
          übernimmt arcBot keinerlei Haftung.
        </li>

        <li>
          d. Eine Haftung seitens arcBot besteht auch nicht, soweit der Kunde
          eigenständig Anpassungen an den KI-Bot vornimmt. Die vorstehende
          Haftungsbeschränkung gilt auch für die persönliche Haftung der
          Mitarbeiter, Vertreter und Organe von arcBot.
        </li>
        <li>
          e. Eine Haftung seitens arcBot besteht auch nicht, soweit der User
          eigenständig Datenschemata seiner Ninox Datenbanken über arcBot
          aktualisiert. Die vorstehende Haftungsbeschränkung gilt auch für die
          persönliche Haftung der Mitarbeiter, Vertreter und Organe von arcBot.
          Der User ist verpflichtet, vor dem Update der Datenschemata ein Backup
          seiner Datenbanken zu erstellen. Für den Verlust von Daten haftet
          arcBot nicht. arcBot haftet auch nicht für die Folgen, die sich aus
          der Aktualisierung der Datenschemata ergeben.
        </li>
        <li>
          f. Der User stellt arcBot von sämtlichen Schäden, Ansprüchen und
          Aufwendungen (einschließlich angemessener Anwaltskosten) Dritter, die
          durch den User entstehen, frei. Der User stellt arcBot von allen
          Ansprüchen, Kosten, Auslagen, Klagen oder Verfahren, einschließlich
          angemessener Anwaltskosten frei, die durch Dritte gegen arcBot (oder
          einen seiner Geschäftsführer, Mitarbeiter oder Erfüllungsgehilfen)
          wegen einer rechts-/ und/oder vertragswidrigen Nutzung der KI-Bot
          geltend gemacht werden, es sei denn, der betreffende Anspruch beruht
          auf einem Mangel der KI-Bot oder einer Verletzung der Verpflichtungen
          von arcBot aus diesen AGB bzw. dem Nutzungsvertrag. Jede Partei
          benachrichtigt die andere Partei unverzüglich schriftlich, nachdem sie
          von einem solchen Anspruch Kenntnis erlangt hat. Die Parteien arbeiten
          bei der Abwehr eines solchen Anspruchs zusammen und stellen sich
          gegenseitig alle Informationen zur Verfügung, die von der anderen
          Partei vernünftigerweise angefordert werden, um einen solchen Anspruch
          abzuwehren und/oder vergleichsweise zu regeln.
        </li>
      </ul>
      <h2 id="m15">AWS S3</h2>
      <h3>Art & Umfang der Verarbeitung</h3>
      <p>
        Um sicherzustellen, dass die Inhalte unserer Website ordnungsgemäß
        bereitgestellt werden, verwenden wir AWS S3. AWS S3 ist ein Dienst von
        Amazon Web Services, Inc., der auf unserer Website als Content Delivery
        Network (CDN) fungiert.
      </p>
      <p>
        Ein CDN trägt dazu bei, die Inhalte unseres Online-Angebots,
        insbesondere Dateien wie Grafiken oder Skripte, über regional oder
        international verteilte Server schneller zur Verfügung zu stellen. Wenn
        Sie auf diese Inhalte zugreifen, wird eine Verbindung zu den Servern von
        Amazon Web Services, Inc. hergestellt, wobei Ihre IP-Adresse und
        möglicherweise Browserdaten wie Ihr User-Agent übertragen werden. Diese
        Daten werden ausschließlich für die oben genannten Zwecke sowie zur
        Gewährleistung der Sicherheit und Funktionalität von AWS S3 verarbeitet.
      </p>
      <p>
        Wir setzen S3 nicht nur zur Speicherung unserer React-App ein, sondern
        auch als Content Delivery Network (CDN), um die Effizienz und
        Geschwindigkeit der Bereitstellung von Inhalten auf unserer Website zu
        optimieren. Es ist wichtig zu betonen, dass wir bei der Nutzung von AWS
        S3 höchsten Wert auf den Schutz Ihrer Daten und Ihre Privatsphäre legen.
      </p>
      <p>
        Beim Zugriff auf Inhalte über unser CDN stellen Sie eine Verbindung zu
        den Servern von Amazon Web Services, Inc. her. Dabei werden
        möglicherweise Informationen wie Ihre IP-Adresse und Browserdaten,
        einschließlich Ihres User-Agents, übertragen. Diese Daten werden jedoch
        ausschließlich zu den oben genannten Zwecken verwendet, nämlich zur
        effizienten Bereitstellung der Website-Inhalte und zur Aufrechterhaltung
        der Sicherheit und Funktionalität von AWS S3. Wir beachten strenge
        Datenschutzrichtlinien und ergreifen alle notwendigen Maßnahmen, um
        sicherzustellen, dass Ihre Daten angemessen geschützt und vertraulich
        behandelt werden.
      </p>
      <p>
        Ihre Privatsphäre ist uns wichtig, und wir sind bestrebt,
        sicherzustellen, dass die Verwendung von AWS S3 im Einklang mit
        geltenden Datenschutzbestimmungen erfolgt.
      </p>
      <strong>Zweck und Rechtsgrundlage</strong>
      <p>
        Die Nutzung des Content Delivery Networks erfolgt auf Grundlage unserer
        berechtigten Interessen, d.h. Interesse an einer sicheren und
        effizienten Bereitstellung sowie der Optimierung unseres Onlineangebotes
        gemäß Art. 6 Abs. 1 lit. f. DSGVO.
      </p>
      <strong>Speicherdauer</strong>
      <p>
        Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
        beeinflussbar, sondern wird von Amazon Web Services, Inc. bestimmt.
        Weitere Hinweise finden Sie in der Datenschutzerklärung für AWS S3:
        https://aws.amazon.com/de/privacy/.
      </p>
      <h2 id="m15">Speicherung der Daten in AWS Cognito</h2>
      <h3>Art & Umfang der Verarbeitung</h3>
      <p>
        Wir legen höchsten Wert auf den Schutz Ihrer Daten und die Sicherheit
        Ihrer Informationen, insbesondere in AWS Cognito (AWS Cognito). In
        diesem Zusammenhang möchten wir Sie über unsere Datenschutzmaßnahmen und
        Sicherheitsvorkehrungen informieren, die sicherstellen, dass Ihre
        persönlichen Daten geschützt sind.
      </p>
      <p>
        Als Nutzer unserer Dienste sollten Sie wissen, dass im Rahmen des
        Datenschutzes ein Modell der geteilten Verantwortung gilt. Gemäß diesem
        Modell ist AWS für den Schutz der globalen Infrastruktur verantwortlich,
        auf der unsere Dienste basieren. Wir sind hingegen dafür verantwortlich,
        Ihre Daten sicher und geschützt zu verwalten und zu verwenden.
      </p>
      <p>
        <strong>Zweck und Rechtsgrundlage</strong>
      </p>
      <p>
        Die Nutzung von AWS Cognito erfolgt auf Grundlage unserer berechtigten
        Interessen, insbesondere dem Interesse an einer sicheren und effizienten
        Bereitstellung sowie der Optimierung unseres Onlineangebotes gemäß Art.
        6 Abs. 1 lit. f. DSGVO.
      </p>
      <p>
        <strong>Speicherdauer</strong>
      </p>
      <p>
        Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns
        beeinflussbar, sondern wird von Amazon Web Services, Inc. bestimmt.
        Weitere Hinweise finden Sie in der Datenschutzerklärung für AWS Cognito:{" "}
        <a href="https://aws.amazon.com/de/privacy/">
          https://aws.amazon.com/de/privacy/
        </a>
        .
      </p>
      <p>
        Wir möchten Sie daran erinnern, dass Ihre Privatsphäre für uns oberste
        Priorität hat, und wir setzen alles daran, Ihre Informationen zu
        schützen. Wenn Sie Fragen zu unseren Datenschutzrichtlinien oder
        Sicherheitsmaßnahmen haben, stehen wir Ihnen gerne zur Verfügung.
      </p>
      <h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
      <p>
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
        an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
        dies erforderlich machen. Wir informieren Sie, sobald durch die
        Änderungen eine Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder
        eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <p>
        Sofern wir in dieser Datenschutzerklärung Adressen und
        Kontaktinformationen von Unternehmen und Organisationen angeben, bitten
        wir zu beachten, dass die Adressen sich über die Zeit ändern können und
        bitten die Angaben vor Kontaktaufnahme zu prüfen.
      </p>
    </div>
  );
};

export default PrivacyPolicyDE;
