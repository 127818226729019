/* eslint-disable @typescript-eslint/indent */

import { Label } from "@radix-ui/react-label";
import React, { useContext, useEffect, useRef, useState } from "react";
import { z } from "zod";
import { ArcBotTableItem } from "./arcbot-table-item";
import { NinoxExporter } from "./ninox-exporter";
import { MyContext } from "../App";
import { exampleDatabase, exampleTables } from "../mock-data";
import { NinoxTable, NinoxTableSchema } from "../ninox-types";
import { Button } from "./ui/button";
import { Skeleton } from "./ui/skeleton";
import { Textarea } from "./ui/textarea";

export const NinoxDatabaseInput = () => {
  const { ninoxDatabaseName, ninoxTables, setNinoxTables } =
    useContext(MyContext);

  const [editJson, setEditJson] = useState(false);
  const [jsonIsValid, setJsonIsValid] = useState<{
    isValid: boolean;
    errorMessage?: string;
  }>({ isValid: true });
  const [displaySchema, setDisplaySchema] = useState(
    JSON.stringify(ninoxTables)
  );
  const [lineNumbers, setLineNumbers] = useState("");
  const [schemaView, setSchemaView] = useState("showSchema");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const url = new URL(window.location.href);
    const schema = url.searchParams.get("ninoxTables");
    if (schema) {
      try {
        const tmp = JSON.parse(schema);
        setNinoxTables(tmp);
        setDisplaySchema(JSON.stringify(tmp, null, 2));
      } catch (e) {
        setDisplaySchema(schema);
      }

      checkIfJsonIsValid(schema);
    }
  }, []);

  useEffect(() => {
    setDisplaySchema(JSON.stringify(ninoxTables, null, 2));
  }, [ninoxTables]);

  useEffect(() => {
    const lineCount = (textareaRef.current?.scrollHeight ?? 14) / 20;
    let lineNumbersTmp = "";
    for (let i = 1; i <= lineCount; i++) {
      lineNumbersTmp += i + "\n";
    }
    setLineNumbers(lineNumbersTmp);
  }, [displaySchema]);

  const checkIfJsonIsValid = (json: string) => {
    try {
      const data = JSON.parse(json);
      // If the above line doesn't throw an error, the JSON is valid

      const validationResult = z.record(NinoxTableSchema).safeParse(data);
      if (!validationResult.success) {
        setJsonIsValid({
          isValid: false,
          errorMessage: validationResult.error.message,
        });
      } else {
        setJsonIsValid({ isValid: true });
      }
    } catch (err) {
      // The JSON is invalid
      setJsonIsValid({ isValid: false, errorMessage: (err as any).message });
    }
  };

  return (
    <div className="flex flex-col h-full pb-0 flex-flow">
      <div className="flex items-center justify-between w-full p-8 flex-flow content-right-header">
        <div className="w-full">
          <h1 className="text-4xl font-bold">Database {ninoxDatabaseName}</h1>
        </div>
        <NinoxExporter />
        <div className="flex gap-2">
          <Button
            className="hidden"
            variant="ghost"
            onClick={() => setSchemaView("showSchema")}
          >
            Schema
          </Button>
          <Button
            className="hidden"
            variant="ghost"
            onClick={() => setSchemaView("showJSON")}
          >
            Code
          </Button>
        </div>
      </div>
      {schemaView === "showSchema" && (
        <div className="h-full p-8 pt-0 overflow-x-auto overflow-y-scroll flex-flow">
          {ninoxTables && Object.keys(ninoxTables).length > 0 ? (
            <div
              className="grid"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                justifyContent: "center",
                gridAutoRows: "auto",
                gridTemplateRows: "auto",
                gap: "10px",
              }}
            >
              {Object.entries(ninoxTables).map(([key, value]) => (
                <ArcBotTableItem key={key} table={{ [key]: value }} />
              ))}
            </div>
          ) : (
            <div
              className="grid h-full"
              style={{
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
                gap: "10px",
              }}
            >
              {Array(9)
                .fill(0)
                .map((_, index) => (
                  <Skeleton
                    className="flex flex-col items-center h-full p-5 justify-items-center bg-slate-200 "
                    key={index}
                  ></Skeleton>
                ))}
            </div>
          )}
          <div className="overflow-y-auto h-[200px] hidden">
            <div className="flex">
              <pre className="py-2 pr-4 text-sm text-right text-gray-500 border-r-2 border-gray-200 select-none">
                {lineNumbers}
              </pre>
              <Textarea
                ref={textareaRef}
                className="font-mono"
                value={displaySchema}
                disabled={!editJson}
                onChange={(e) => {
                  setDisplaySchema(e.target.value);
                  checkIfJsonIsValid(e.target.value);
                }}
                id="jsonObject"
                placeholder='{"key":"value"}'
              />
            </div>
          </div>
          {!jsonIsValid.isValid && (
            <Label className="text-red-500 ">{`JSON not valid! Error: ${jsonIsValid.errorMessage}`}</Label>
          )}
        </div>
      )}

      {schemaView === "showJSON" && (
        <div>
          <div className="flex w-full gap-2">
            {!!editJson && (
              <Button
                variant="outline"
                onClick={() => {
                  const url = new URL(window.location.href);
                  const schema = url.searchParams.get("ninoxTables");

                  if (schema) {
                    try {
                      const tmp = JSON.parse(schema);
                      setNinoxTables(tmp);
                      setDisplaySchema(JSON.stringify(tmp, null, 2));
                    } catch (e) {
                      setDisplaySchema(schema);
                    }
                    checkIfJsonIsValid(schema);
                  }
                  setEditJson(!editJson);
                }}
              >
                Cancel
              </Button>
            )}
            {!!editJson && (
              <Button
                disabled={!jsonIsValid.isValid}
                onClick={() => {
                  const schema = JSON.parse(displaySchema);
                  setNinoxTables(schema);
                  setDisplaySchema(JSON.stringify(schema, null, 2));
                  setEditJson(!editJson);
                }}
              >
                Save
              </Button>
            )}
            {!editJson && (
              <div className="flex hidden w-full gap-2">
                <Button
                  onClick={() => {
                    setEditJson(!editJson);
                    checkIfJsonIsValid(displaySchema);
                  }}
                >
                  Edit
                </Button>
                <div className="ml-auto text-sm">
                  <div
                    className="cursor-pointer hover:underline"
                    onClick={() => {
                      setNinoxTables(exampleTables);
                      setDisplaySchema(
                        JSON.stringify(exampleDatabase, null, 2)
                      );
                      setJsonIsValid({ isValid: true });
                    }}
                  >
                    Set example database
                  </div>
                  <div
                    className="cursor-pointer hover:underline"
                    onClick={() => {
                      setNinoxTables({});
                      setDisplaySchema(JSON.stringify([], null, 2));
                      setJsonIsValid({ isValid: true });
                      setLineNumbers("");
                    }}
                  >
                    Clear database
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
