const PrivacyPolicyEN = () => {
  return (
    <div>
      <h2 className="pt-4" id="m4158">
        Preamble
      </h2>
      <p>
        With the following terms and privacy policy, we want to inform you about
        liability, the types of your personal data (hereinafter also referred to
        briefly as "data") that we process for what purposes and to what extent
        as part of providing our application.
      </p>
      <p>The terms used are not gender-specific.</p>
      <p>Date: January 14, 2024</p>
      <h2>Table of Contents</h2>{" "}
      <ul className="list-disc pl-14 index">
        <li>
          <a className="index-link" href="#m4158">
            Preamble
          </a>
        </li>
        <li>
          <a className="index-link" href="#m3">
            Controller
          </a>
        </li>
        <li>
          <a className="index-link" href="#mOverview">
            Overview of Processing
          </a>
        </li>
        <li>
          <a className="index-link" href="#m2427">
            Relevant Legal Bases
          </a>
        </li>
        <li>
          <a className="index-link" href="#m27">
            Security Measures
          </a>
        </li>
        <li>
          <a className="index-link" href="#m25">
            Transfer of Personal Data
          </a>
        </li>
        <li>
          <a className="index-link" href="#m24">
            International Data Transfers
          </a>
        </li>
        <li>
          <a className="index-link" href="#m12">
            Deletion of Data
          </a>
        </li>
        <li>
          <a className="index-link" href="#m10">
            Rights of Data Subjects
          </a>
        </li>
        <li>
          <a className="index-link" href="#m134">
            Use of Cookies
          </a>
        </li>
        <li>
          <a className="index-link" href="#m225">
            Provision of the Online Offer and Web Hosting
          </a>
        </li>
        <li>
          <a className="index-link" href="#m367">
            Registration, Login, and User Account
          </a>
        </li>
        <li>
          <a className="index-link" href="#m432">
            Community Features
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Contact and Inquiry Management
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Storage of Data in Ninox
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Liability
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            AWS S3
          </a>
        </li>
        <li>
          <a className="index-link" href="#m182">
            Storage of Data in AWS Cognito
          </a>
        </li>
        <li>
          <a className="index-link" href="#m15">
            Amendment and Update of the Privacy Policy
          </a>
        </li>
      </ul>
      <h2 id="m3">Controller</h2>
      <p>
        Jakob Jordan
        <br />
        Jänickendorferstr. 64A
        <br />
        14943 Luckenwalde
      </p>
      Email Address:{" "}
      <a href="mailto:jakob.jordan@arbot.de">jakob.jordan@arbot.de</a>
      Impressum: www.arbot.de/impressum
      <h2 id="mOverview">Overview of Processing</h2>
      <p>
        The following overview summarizes the types of data processed and the
        purposes of their processing and refers to the data subjects.
      </p>
      <h3>Types of processed data</h3>
      <ul>
        <li>Inventory data.</li>
        <li>Contact data.</li>
        <li>Content data.</li>
        <li>Usage data.</li>
        <li>Meta, communication, and process data.</li>
      </ul>
      <h3>Categories of data subjects</h3>
      <ul>
        <li>Communication partners.</li>
        <li>Users.</li>
      </ul>
      <h3>Purposes of processing</h3>
      <ul>
        <li>
          Provision of contractual services and fulfillment of contractual
          obligations.
        </li>
        <li>Contact inquiries and communication.</li>
        <li>Security measures.</li>
        <li>Management and response to inquiries.</li>
        <li>Feedback.</li>
        <li>Provision of our online offering and user-friendliness.</li>
        <li>Information technology infrastructure.</li>
      </ul>
      <h2 id="m2427">Relevant Legal Bases</h2>
      <p>
        <strong>Relevant legal bases under the GDPR: </strong>Below is an
        overview of the legal bases of the GDPR on which we process personal
        data. Please note that, in addition to the provisions of the GDPR,
        national data protection regulations may apply in your or our country of
        residence or domicile. Furthermore, if more specific legal bases are
        applicable in individual cases, we will inform you of these in the data
        protection declaration.
      </p>
      <ul>
        <li>
          Consent (Article 6(1)(a) GDPR) - The data subject has given consent to
          the processing of their personal data for a specific purpose or
          purposes.
        </li>
        <li>
          Performance of a contract and pre-contractual inquiries (Article
          6(1)(b) GDPR) - The processing is necessary for the performance of a
          contract to which the data subject is party or to take steps at the
          request of the data subject prior to entering into a contract.
        </li>
        <li>
          Legitimate interests (Article 6(1)(f) GDPR) - The processing is
          necessary for the purposes of the legitimate interests pursued by the
          controller or by a third party, except where such interests are
          overridden by the interests or fundamental rights and freedoms of the
          data subject which require protection of personal data.
        </li>
        <li>
          Compliance with a legal obligation (Article 6(1)(c) GDPR) - The
          processing is necessary for compliance with a legal obligation to
          which the controller is subject.
        </li>
        <li>
          Protection of vital interests (Article 6(1)(d) GDPR) - The processing
          is necessary in order to protect the vital interests of the data
          subject or of another natural person.
        </li>
      </ul>
      <p>
        <strong>Security Measures</strong> We take appropriate technical and
        organizational measures in accordance with the legal requirements,
        taking into account the state of the art, the costs of implementation,
        and the nature, scope, context, and purposes of processing, as well as
        the risk of varying likelihood and severity for the rights and freedoms
        of natural persons, to ensure a level of security appropriate to the
        risk.
      </p>
      <p>
        Measures include, in particular, ensuring the confidentiality,
        integrity, and availability of data by controlling physical and
        electronic access to the data as well as access, input, disclosure,
        availability, and separation of the data. We have also set up procedures
        to ensure the exercise of data subject rights, data deletion, and data
        vulnerability. Furthermore, we consider the protection of personal data
        already during the development or selection of hardware, software, and
        processes, in accordance with the principle of data protection through
        technology design and data protection-friendly default settings (Article
        25 GDPR).
      </p>
      <p>
        <strong>Transfer of Personal Data</strong> In the context of our
        processing of personal data, it may be necessary to transfer data to
        other locations (e.g., external service providers, third parties, or
        affiliated companies) or to disclose them to third parties. This may
        include, but is not limited to, the following:
      </p>
      <ul>
        <li>
          Disclosure to payment service providers (e.g., banks, payment service
          providers, payment gateways).
        </li>
        <li>
          Order processing and fulfillment (e.g., logistics companies,
          suppliers).
        </li>
        <li>Management of customer and user inquiries.</li>
        <li>Contact management and communication.</li>
        <li>Web hosting.</li>
        <li>Community management.</li>
        <li>Contact and inquiry management.</li>
      </ul>
      <p>
        If we disclose data to other companies in our group, transmit them to
        third parties, or otherwise grant them access to the data, this will
        only be done on the basis of a legal permission (e.g., if the data
        transfer to third parties, such as payment service providers, is
        necessary for contract fulfillment), users have consented to a legal
        obligation to do so, or on the basis of our legitimate interests (e.g.,
        when using agents, web hosts, etc.).
      </p>
      <p>
        If we commission third parties to process data on the basis of a
        so-called "data processing agreement," this is done on the basis of
        Article 28 GDPR.
      </p>
      <h2 id="m24">International Data Transfers</h2>
      <p>
        If we process data in a third country (i.e., outside the European Union
        (EU) or the European Economic Area (EEA)) or if this occurs in the
        context of using third-party services or disclosing or transferring data
        to third parties, this will only take place to fulfill our
        (pre)contractual obligations, on the basis of your consent, due to a
        legal obligation, or on the basis of our legitimate interests. Subject
        to legal or contractual permissions, we process or allow data to be
        processed in a third country only if the special requirements of Article
        44 et seq. GDPR are met. This means that the processing is carried out,
        for example, on the basis of special guarantees, such as the officially
        recognized determination of a data protection level corresponding to the
        EU (e.g., for the USA through the "Privacy Shield") or compliance with
        officially recognized special contractual obligations (so-called
        "standard contractual clauses").
      </p>
      <p>
        Rights of Data Subjects
        <br />
        You have the right to request confirmation as to whether the data in
        question will be processed and to be informed of this data and to
        receive further information and a copy of the data in accordance with
        the legal requirements.
      </p>
      <p>
        You have the right, in accordance with the legal requirements, to
        request the completion of the data concerning you or the rectification
        of the incorrect data concerning you.
      </p>
      <p>
        In accordance with the legal requirements, you have the right to request
        that the relevant data be deleted immediately or, alternatively, to
        demand a restriction of the processing of the data in accordance with
        the legal requirements.
      </p>
      <p>
        You have the right to receive the data concerning you that you have
        provided to us and to request its transmission to other responsible
        parties in accordance with the legal requirements.
      </p>
      <p>
        You also have the right to file a complaint with the competent
        supervisory authority in accordance with the legal requirements.
      </p>
      <p>
        You have the right to withdraw your consent with effect for the future.
      </p>
      <h2 id="m15">Amendment and Update of the Privacy Policy</h2>
      <p>
        We ask you to inform yourself regularly about the content of our data
        protection declaration. We will adapt the data protection declaration as
        soon as changes in the data processing carried out by us make this
        necessary. We will inform you as soon as the changes require your
        cooperation (e.g., consent) or other individual notification.
      </p>
      <p>
        If we provide addresses and contact information of companies and
        organizations in this data protection declaration, we ask you to note
        that addresses may change over time and to verify the information before
        contacting us.
      </p>
      <h2 id="m367">Registration, Sign-In, and User Account</h2>
      <p>
        Users can create a user account. During the registration process, users
        are provided with the necessary mandatory information and processed for
        the purpose of providing the user account based on contractual
        fulfillment. The processed data includes login information (username,
        password, email address, and whether a Ninox account exists).
      </p>
      <p>
        As part of using our registration and sign-in features, as well as using
        the user account, we store the IP address and the timestamp of each user
        action. This storage is based on our legitimate interests and those of
        users in protection against misuse and other unauthorized use. In
        general, this data is not shared with third parties unless it is
        necessary to pursue our claims or there is a legal obligation to do so.
      </p>
      <p>
        Users may be informed via email about operations relevant to their user
        account, such as technical changes.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed Data Types:</strong> Master data (e.g., names,
          addresses); Contact data (e.g., email, phone numbers); Content data
          (e.g., entries in online forms); Meta, communication, and process data
          (e.g., IP addresses, timestamps, identification numbers, consent
          status).
        </li>
        <li>
          <strong>Data Subjects:</strong> Users (e.g., website visitors, users
          of online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual
          services and fulfillment of contractual obligations; Security
          measures; Management and response to inquiries; Provision of our
          online offering and user-friendliness.
        </li>
        <li className="">
          <strong>Legal Bases:</strong> Fulfillment of a contract and
          pre-contractual inquiries (Art. 6(1)(b) GDPR); Legitimate interests
          (Art. 6(1)(f) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Additional Notes on Processing Procedures, Processes, and Services:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Registration with Real Names:</strong> Due to the nature of
          our community, we ask users to use our service only with their real
          names. The use of pseudonyms is not allowed.
        </li>
        <li>
          <strong>User Profiles Are Not Public:</strong> User profiles are not
          publicly visible or accessible.
        </li>
        <li>
          <strong>Data Deletion After Termination:</strong> When users terminate
          their user accounts, their data related to the user account will be
          deleted, subject to any legal permissions, obligations, or user
          consent.
        </li>
      </ul>
      <h2 id="m432">Community Features</h2>
      <p>
        The community features we provide allow users to engage in conversations
        or exchanges with each other. Please note that the use of community
        features is only allowed in compliance with applicable laws, our terms
        and policies, and the rights of other users and third parties.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed Data Types:</strong> Usage data (e.g., visited
          websites, interest in content, access times); Meta, communication, and
          process data (e.g., IP addresses, timestamps, identification numbers,
          consent status).
        </li>
        <li>
          <strong>Data Subjects:</strong> Users (e.g., website visitors, users
          of online services).
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Provision of contractual
          services and fulfillment of contractual obligations; Security
          measures.
        </li>
        <li className="">
          <strong>Legal Bases:</strong> Fulfillment of a contract and
          pre-contractual inquiries (Art. 6(1)(b) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Additional Notes on Processing Procedures, Processes, and Services:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>User Contributions Are Public:</strong> User-generated posts
          and content are publicly visible and accessible.
        </li>
        <li>
          <strong>Storage of Data for Security Purposes:</strong> User
          contributions and other user inputs are processed for the purposes of
          community and conversation functions and are not disclosed to third
          parties, except for legal obligations or permissions. A disclosure
          obligation may arise, especially in the case of unlawful contributions
          for the purpose of legal prosecution.
        </li>
      </ul>
      <h2 id="m182">Contact and Inquiry Management</h2>
      <p>
        When contacting us (e.g., by mail, contact form, email, phone, or via
        social media) and as part of existing user and business relationships,
        the information of the inquiring individuals is processed to the extent
        necessary to respond to the contact inquiries and any requested actions.
      </p>
      <ul className="m-elements">
        <li>
          <strong>Processed Data Types:</strong> Contact data (e.g., email,
          phone numbers); Content data (e.g., entries in online forms); Usage
          data (e.g., visited websites, interest in content, access times);
          Meta, communication, and process data (e.g., IP addresses, timestamps,
          identification numbers, consent status).
        </li>
        <li>
          <strong>Data Subjects:</strong> Communication partners.
        </li>
        <li>
          <strong>Purposes of Processing:</strong> Contact inquiries and
          communication; Management and response to inquiries; Feedback (e.g.,
          collecting feedback via online form); Provision of our online offering
          and user-friendliness.
        </li>
        <li className="">
          <strong>Legal Bases:</strong> Legitimate interests (Art. 6(1)(f)
          GDPR); Fulfillment of a contract and pre-contractual inquiries (Art.
          6(1)(b) GDPR).
        </li>
      </ul>
      <p>
        <strong>
          Additional Notes on Processing Procedures, Processes, and Services:
        </strong>
      </p>
      <ul className="m-elements">
        <li>
          <strong>Contact Form:</strong> When users contact us through our
          contact form, email, or other communication channels, we process the
          data provided to us in this context to address the submitted
          inquiries.
        </li>
      </ul>
      <h2 id="ninox-data-storage">Data Storage in Ninox</h2>
      <h3>Type and Extent of Processing</h3>
      <p>
        We store various types of data in our Ninox database to ensure the
        functionality of our services. The stored data includes:
      </p>
      <ul>
        <li>
          User Data: This includes email addresses, first and last names, IP
          addresses, unencrypted passwords, company names, and initials.
        </li>
        <li>
          User Sessions: This includes session IDs for managing user sessions.
        </li>
        <li>
          Prompt Chat History: This stores information such as user inputs,
          generated database schemas, table IDs, table names, field IDs, field
          names, merging (yes/no), users, Ninox environment, and more.
        </li>
        <li>
          User Feedback: This encompasses user inputs, generated database
          schemas before and after changes, table IDs, table names, field IDs,
          field names, merging (yes/no), user ratings, comments, conversations,
          Ninox environment, and more.
        </li>
        <li>
          Ninox Environments: These include information such as Ninox URLs, team
          IDs, database IDs, and API keys (unencrypted).
        </li>
        <li>
          User Events: This includes IP addresses, country information, user
          inputs, generated database schemas before and after changes, table
          IDs, table names, field IDs, field names, merging (yes/no), user
          ratings, comments, conversations, Ninox environment, and more.
        </li>
        <li>
          User Feature Requests: These include titles, descriptions, users,
          votes, and more.
        </li>
      </ul>
      <p>
        We use Ninox to securely and efficiently store and manage this data. We
        want to emphasize that the protection of your data and your privacy is
        of the utmost importance to us.
      </p>
      <p>
        When accessing and using our services, your data may be collected and
        stored in our Ninox database. We use this data solely for the provision
        and improvement of our services and to ensure their security and
        functionality.
      </p>
      <p>
        Your privacy is important to us, and we take all necessary measures to
        ensure that the storage and processing of your data are in accordance
        with applicable data protection regulations.
      </p>
      <strong>Purpose and Legal Basis</strong>
      <p>
        The storage of your data in our Ninox database is based on our
        legitimate interests, particularly the interest in securely and
        efficiently providing and optimizing our services, in accordance with
        Art. 6(1)(f) GDPR.
      </p>
      <strong>Storage Duration</strong>
      <p>
        The specific storage duration of processed data is not influenced by us
        but determined by our Ninox database. For more information on Ninox's
        data protection practices, please refer to Ninox's privacy policy:
        <a
          href="https://ninox.com/de/datenschutz"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ninox.com/de/datenschutz
        </a>
        .
      </p>
      <p>
        We appreciate your trust in our services and take the protection of your
        data very seriously. If you have any questions about our privacy
        policies or the processing of your data, please feel free to contact us.
      </p>
      <h2 id="ninox-liability">Liability</h2>
      <ul>
        <li>
          a. arcBot is fully liable for damages resulting from the intentional
          or grossly negligent violation of life, body, or health, as well as
          under the provisions of the Product Liability Act.
        </li>

        <li>
          b. arcBot's liability does not extend further. In particular, arcBot
          shall not be liable for initial defects unless the conditions of point
          a. are met.
        </li>

        <li>
          c. arcBot shall not be liable for the functionality of the app itself.
          The service is provided "as is," without warranties. If Ninox releases
          a new version of the Ninox software that renders the AI bot
          non-functional, arcBot assumes no liability.
        </li>

        <li>
          d. arcBot is not liable when the customer makes independent
          adjustments to the AI bot. The above liability limitation also applies
          to the personal liability of arcBot's employees, representatives, and
          officers.
        </li>

        <li>
          e. arcBot is not liable when the user independently updates data
          schemas of their Ninox databases via arcBot. The above liability
          limitation also applies to the personal liability of arcBot's
          employees, representatives, and officers. The user is obligated to
          create a backup of their databases before updating data schemas.
          arcBot is not liable for data loss. arcBot is also not liable for the
          consequences arising from the update of data schemas.
        </li>

        <li>
          f. The user indemnifies arcBot from all damages, claims, and expenses
          (including reasonable attorney's fees) of third parties arising from
          the user. The user indemnifies arcBot from all claims, costs,
          expenses, lawsuits, or proceedings, including reasonable attorney's
          fees, brought against arcBot (or any of its directors, employees, or
          agents) by third parties due to unlawful and/or contractual use of the
          AI bot, unless the claim in question is based on a defect in the AI
          bot or a breach of arcBot's obligations under these terms and
          conditions or the usage agreement. Each party shall promptly notify
          the other party in writing upon becoming aware of such a claim. The
          parties shall cooperate in defending such a claim and shall provide
          each other with all information reasonably requested by the other
          party to defend and/or settle such a claim.
        </li>
      </ul>
      <h2 id="m15">AWS S3</h2>
      <h3>Type and Extent of Processing</h3>
      <p>
        To ensure the proper delivery of our website's content, we use AWS S3.
        AWS S3 is a service provided by Amazon Web Services, Inc., which acts as
        a Content Delivery Network (CDN) on our website.
      </p>
      <p>
        A CDN helps deliver the content of our online offering, especially files
        like graphics or scripts, more quickly through regionally or
        internationally distributed servers. When you access this content, a
        connection is made to the servers of Amazon Web Services, Inc., with
        your IP address and possibly browser data like your user-agent being
        transmitted. These data are processed solely for the purposes mentioned
        above and for ensuring the security and functionality of AWS S3.
      </p>
      <p>
        We use S3 not only for storing our React app but also as a Content
        Delivery Network (CDN) to optimize the efficiency and speed of content
        delivery on our website. It's important to emphasize that we place a
        high value on the protection of your data and your privacy.
      </p>
      <p>
        When accessing content through our CDN, you establish a connection to
        the servers of Amazon Web Services, Inc. During this process,
        information such as your IP address and browser data, including your
        user-agent, may be transmitted. However, these data are used exclusively
        for the purposes of efficiently delivering website content and
        maintaining the security and functionality of AWS S3. We adhere to
        strict privacy policies and take all necessary measures to ensure that
        your data are adequately protected and treated confidentially.
      </p>
      <p>
        Your privacy is important to us, and we strive to ensure that the use of
        AWS S3 is in compliance with applicable data protection regulations.
      </p>
      <strong>Purpose and Legal Basis</strong>
      <p>
        The use of the Content Delivery Network is based on our legitimate
        interests, specifically our interest in securely and efficiently
        providing and optimizing our online offering, pursuant to Art. 6(1)(f)
        GDPR.
      </p>
      <strong>Storage Duration</strong>
      <p>
        The specific storage duration of processed data is not influenced by us
        but determined by Amazon Web Services, Inc. For more information, please
        refer to the privacy policy for AWS S3:
        <a
          href="https://aws.amazon.com/de/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://aws.amazon.com/de/privacy/
        </a>
        .
      </p>
      <h2 id="m15">Data Privacy and Security in AWS Cognito</h2>
      <h3>Type and Extent of Processing</h3>
      <p>
        We place the highest value on the protection of your data and the
        security of your information, especially in AWS Cognito (AWS Cognito).
        In this context, we would like to inform you about our data protection
        measures and security precautions that ensure the protection of your
        personal data.
      </p>
      <p>
        As a user of our services, you should be aware that a model of shared
        responsibility applies to data protection. According to this model, AWS
        is responsible for protecting the global infrastructure on which our
        services are based. We are responsible for securely and safely managing
        and using your data.
      </p>
      <strong>Purpose and Legal Basis</strong>
      <p>
        The use of AWS Cognito is based on our legitimate interests,
        particularly the interest in securely and efficiently providing and
        optimizing our online offering, pursuant to Art. 6(1)(f) GDPR.
      </p>
      <strong>Storage Duration</strong>
      <p>
        The specific storage duration of processed data is not influenced by us
        but determined by Amazon Web Services, Inc. For more information, please
        refer to the privacy policy for AWS Cognito:
        <a
          href="https://aws.amazon.com/de/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://aws.amazon.com/de/privacy/
        </a>
        .
      </p>
      <p>
        We would like to remind you that your privacy is our top priority, and
        we are committed to protecting your information. If you have any
        questions about our data protection policies or security measures,
        please feel free to contact us.
      </p>
      <h2 id="m15">Changes and Updates to the Privacy Policy</h2>
      <p>
        We kindly ask you to regularly review the content of our privacy policy.
        We adjust the privacy policy as soon as changes in the data processing
        carried out by us make this necessary. We will inform you as soon as
        these changes require your cooperation (e.g., consent) or individual
        notification.
      </p>
      <p>
        If we provide addresses and contact information of companies and
        organizations in this privacy policy, please note that these addresses
        may change over time, and we ask you to verify the information before
        contacting them.
      </p>
    </div>
  );
};

export default PrivacyPolicyEN;
