import { CaretUp } from "@phosphor-icons/react";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { MyContext } from "../../App";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

export type FeatureRequest = {
  id: number;
  title: string;
  description: string;
  upvotes: number;
  status: { statusId: number; statusTitle: string; statusColor: string };
  creator: { userInitials: string };
  comments: { title: string; description: string }[];
};
export type UserVoting = {
  id: number;
};

export const FeatureRequestList = () => {
  const { apiClient, setLoginOpen, signedInUser } = useContext(MyContext);

  const fetchFeatureRequestQuery = useQuery(
    ["featureRequests", { userEmail: signedInUser?.userEmail }],
    () => fetchFeatureRequests({ userEmail: signedInUser?.userEmail })
    // { enabled: false }
  );

  useEffect(() => {
    if (signedInUser) {
      void fetchFeatureRequestQuery.refetch();
    }
  }, [signedInUser]);

  const fetchFeatureRequests = async ({
    userEmail,
  }: {
    userEmail?: string;
  }) => {
    try {
      const response = await apiClient.loadTicketPage(userEmail);

      if (response.status === 200) {
        // Parsing the response body to JSON
        const responseBody = response.data;
        if (Array.isArray(responseBody.tickets)) {
          return {
            tickets: responseBody.tickets,
            votings: responseBody.votings,
          };
        } else {
          console.error(
            "Tickets are not in expected array format:",
            responseBody
          );
        }
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (error) {
      console.error(
        "An error occurred while loading the feature requests:",
        error
      );
    }
  };

  const handleUpvoteClick = async (featureRequest: FeatureRequest) => {
    try {
      const response = await apiClient.createVoting({
        featureRequestId: featureRequest.id,
      });

      let responseBody = JSON.parse(response.data.body);
      const upvotes = 0; //responseBody.upvotes;
      if (response.status === 200) {
        // console.log("Successfully upvoted feature request");
        return upvotes;
      } else {
        console.error("Failed to upvote feature request");
      }
    } catch (error) {
      console.error(
        "An error occurred while upvoting the feature request:",
        error
      );
    }
  };

  const upvoteMutation = useMutation(
    "handleUpvoteClick",
    ({
      // email,
      featureRequest,
    }: {
      email: string;
      featureRequest: FeatureRequest;
    }) => handleUpvoteClick(featureRequest),
    {
      onSuccess: async () => {
        // console.log("onSuccess");
        await fetchFeatureRequestQuery.refetch();
      },
      onError: (_e) => {
        console.log("Error on publish");
      },
    }
  );
  const [isLoading, setIsLoading] = useState<{ [key: number]: boolean }>({});

  return (
    <Card className="flex flex-col w-full h-full">
      <CardHeader>
        <CardTitle>Ticket Submissions</CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent className="flex flex-col overflow-auto">
        {fetchFeatureRequestQuery.isLoading ? (
          <div className="flex items-center justify-center flex-grow w-full h-full">
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>
        ) : fetchFeatureRequestQuery.data &&
          fetchFeatureRequestQuery.data.tickets.length > 0 ? (
          <Table className="h-full">
            <TableCaption></TableCaption>
            <TableHeader className="sticky top-0 bg-white">
              <TableRow>
                <TableHead className="w-[100px]">Upvote</TableHead>
                <TableHead>Title</TableHead>
                <TableHead>Descritption</TableHead>
                <TableHead className="align-center">Creator</TableHead>
                <TableHead className="align-center">Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fetchFeatureRequestQuery.data.tickets.map((featureRequest) => {
                // Determine if this featureRequest has been upvoted by the user
                const isUpvoted = fetchFeatureRequestQuery.data?.votings.some(
                  (voting) => voting.id === featureRequest.id
                );
                return (
                  <TableRow key={featureRequest.id}>
                    <TableCell className="text-right">
                      <Button
                        variant={isUpvoted ? "default" : "outline"}
                        className="flex flex-col w-full gap-1 h-13 min-h-auto"
                        onClick={async () => {
                          if (!signedInUser?.userEmail) {
                            setLoginOpen(true);
                          } else {
                            setIsLoading((prevState) => ({
                              ...prevState,
                              [featureRequest.id]: true,
                            })); // Start loading for this row
                            await upvoteMutation.mutateAsync({
                              email: signedInUser.userEmail,
                              featureRequest,
                            });
                            setIsLoading((prevState) => ({
                              ...prevState,
                              [featureRequest.id]: false,
                            })); // End loading for this row
                          }
                        }}
                      >
                        {isLoading[featureRequest.id] ? (
                          <div className="flex flex-shrink-0 h-13">
                            <LoadingSpinner
                              color={isUpvoted ? "text-white" : "text-black"}
                            />
                          </div>
                        ) : (
                          <>
                            <CaretUp
                              className="flex-shrink-0"
                              size={18}
                              color={isUpvoted ? "#fff" : "#ddd"}
                              weight="fill"
                            />
                            {featureRequest.upvotes}
                          </>
                        )}
                      </Button>
                    </TableCell>
                    <TableCell className="font-medium">
                      {featureRequest.title}
                    </TableCell>
                    <TableCell>{featureRequest.description}</TableCell>
                    <TableCell>
                      <div className="flex items-center justify-center h-100">
                        {featureRequest.creator.userInitials}
                      </div>
                    </TableCell>
                    <TableCell className="align-center">
                      <Badge
                        variant="default"
                        className="text-nowrap"
                        style={{
                          backgroundColor: featureRequest.status.statusColor,
                        }}
                      >
                        {featureRequest.status.statusTitle}
                      </Badge>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <p>No feature requests found.</p>
        )}
      </CardContent>
    </Card>
  );
};
