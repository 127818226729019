import LottieAnimation from "../components/ui/loadingBubble";
import Logo from "../components/ui/logo";

function WaitlistRequested() {
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId") || "000";

  return (
    <div className="flex flex-col items-center justify-center h-screen p-6 bg-custom-lightblue">
      <div className="mb-5">
        <Logo />
      </div>
      <div className="flex flex-col items-center justify-center h-auto p-10 bg-white rounded-lg shadow-lg w-px-400">
        <h1 className="text-2xl font-bold text-center">
          Your are on the Waitlist
        </h1>
        <p className="mt-4 text-center">
          Thank your for joining the Waitlist. In Early Access, you can connect
          and test arcBot with your own database.
        </p>
        <b className="text-center">Your Waitlist Id is {userId}.</b>
        <LottieAnimation height={120} width={120} />
        <p className="mb-5 text-sm text-center text-lighgrey">
          Please check your spam folder if you did not receive any email.
          <br />
          If you still did not receive any email, please contact us at{" "}
          <a href="mailto:office@arc-rider.com">office@arc-rider.com</a>.
        </p>
      </div>
    </div>
  );
}

export default WaitlistRequested;
