import { Dispatch, SetStateAction, useContext, useState } from "react";
import PrivacyPolicyDE from "./privacy-policy_de";
import PrivacyPolicyEN from "./privacy-policy_en";
import { Button } from "./ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { ScrollArea } from "./ui/scroll-area";
export const PoliciesDialog = ({
  termsOpen,
  setTermsOpen,
}: {
  termsOpen: boolean;
  setTermsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const handleClose = () => {
    setTermsOpen(false);
  };
  const handleAcceptTerms = () => {
    localStorage.setItem("acceptedTerms", "true");
    window.location.reload();
  };

  return (
    <Dialog open={termsOpen}>
      <DialogContent className="sm:max-w-[650px]">
        <DialogHeader>
          <DialogTitle className="text-3xl">Terms & Conditions</DialogTitle>
          <DialogDescription>
            Please accept our terms and privacy policy to continue using the
            arcBot app.
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="terms-dialog h-[400px] border rounded px-5">
          <PrivacyPolicyEN />
        </ScrollArea>
        <DialogFooter className="flex sm:justify-between">
          <Button type="submit" variant="outline" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" onClick={handleAcceptTerms}>
            Accept and continue
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
