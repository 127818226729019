import React from "react";
import { Header } from "../../components/Header";
import PrivacyPolicyDE from "../../components/privacy-policy_de";
import PrivacyPolicyEN from "../../components/privacy-policy_en";
import { Button } from "../../components/ui/button";
export const TermsRoute = "/terms";
export const Terms = () => {
  const [language, setLanguage] = React.useState("EN");

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="container px-4 py-10 mx-auto max-w-[850px] content-page">
        <h1 className="">
          {language === "EN"
            ? "Terms & Privacy Policy"
            : "Bedingungen & Datenschutzbestimmungen"}
        </h1>
        <div className="flex gap-2">
          <Button variant="outline" onClick={() => setLanguage("EN")}>
            EN
          </Button>
          <Button variant="outline" onClick={() => setLanguage("DE")}>
            DE
          </Button>
        </div>
        {language === "EN" ? <PrivacyPolicyEN /> : <PrivacyPolicyDE />}
      </div>
    </div>
  );
};
