import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../components/ui/alert-dialog";

const createPromise = () => {
  let resolver;
  return [
    new Promise((resolve, reject) => {
      resolver = resolve;
    }),
    resolver,
  ];
};

const useConfirm = () => {
  const [open, setOpen] = useState(false);
  const [resolver, setResolver] = useState<{ resolve: any }>({ resolve: null });

  const [alertTitle, setAlertTitle] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const getConfirmation = async ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }) => {
    setAlertTitle(title);
    setAlertDescription(message);
    setOpen(true);
    const [promise, resolve] = createPromise();
    setResolver({ resolve });
    return promise;
  };

  const onClick = async (status: boolean) => {
    setOpen(false);
    await resolver.resolve(status);
  };

  const Confirmation = () => (
    <AlertDialog open={open} onOpenChange={setOpen}>
      {/* <AlertDialogTrigger>Open</AlertDialogTrigger> */}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{alertTitle}</AlertDialogTitle>
          <AlertDialogDescription>{alertDescription}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => onClick(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => onClick(true)}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  return { getConfirmation, Confirmation };
  //   return Confirmation;
};

export default useConfirm;
