/* eslint-disable @typescript-eslint/indent */
import { useContext, useState } from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import { MyContext } from "../App";
import NinoxClient from "../ninox-client";
import { Button } from "./ui/button";
import useAlert from "../utils/useAlert";

export const NinoxExporter = () => {
  const [databaseSelected, setDatabase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const { databaseConnected, ninoxTables, signedInUser, globalCode } =
    useContext(MyContext);

  const { getAlert, Alert } = useAlert();

  return (
    <div className="flex gap-2">
      {databaseSelected && (
        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={async () => {
              const findEnvironment = signedInUser?.ninoxEnvironments?.find(
                (e) =>
                  e.environmentId ===
                  Number(signedInUser?.selectedUserEnvironmentId)
              );
              // console.log(`findEnvironment:${JSON.stringify(findEnvironment)}`);
              if (!findEnvironment) {
                await getAlert({
                  title: "Error",
                  message: "Having a problem with the ninox connector",
                });
                return;
              }
              if (!databaseConnected) {
                await getAlert({
                  title: "Error",
                  message: "Ninox connector is not connected",
                });
                return;
              }
              setIsLoading(true);
              try {
                if (!ninoxTables) return;
                const ninoxClient = new NinoxClient(
                  findEnvironment.cloudUrl,
                  findEnvironment.apiKey
                );
                ninoxClient.setTeamId(findEnvironment.teamId);
                ninoxClient.setDatabaseId(findEnvironment.databaseId);

                const timeout = new Promise((resolve, reject) => {
                  setTimeout(() => {
                    reject(new Error("Operation timed out"));
                  }, 10000); // 10 seconds
                });

                try {
                  await Promise.race([
                    ninoxClient.updateTables(ninoxTables, globalCode),
                    timeout,
                  ]);
                } catch (error) {
                  console.error(error);
                  alert(
                    `Database Update takes unusually long. Please post the ninoxTables to the discord feedback: ${JSON.stringify(ninoxTables)}`
                  );
                }
              } catch (err) {
                console.log(err);
              }
              setIsLoading(false);
            }}
          >
            {isLoading ? <LoadingSpinner /> : "Update Database"}
          </Button>
          <Alert />
        </div>
      )}
    </div>
  );
};
