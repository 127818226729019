import { NinoxDatabase, NinoxDatabaseSchema, NinoxTable } from "./ninox-types";

export const exampleTables: Record<string, NinoxTable> = {
  BOOKING: {
    nextFieldId: 7,
    caption: "Booking",
    fields: {
      CHECK_IN: {
        base: "date",
        caption: "Check inn",
      },
      CHECK_OUT: {
        base: "date",
        caption: "Check out",
      },
      APARTMENT_ID: {
        base: "ref",
        caption: "Apartment",
        refTypeId: "Apartment",
      },
      ACTIVE: {
        base: "boolean",
        caption: "Active",
      },
      // CUSTOMER_ID: {
      //   base: 'ref',
      //   caption: 'Customer',
      //   refTypeId: 'Customer',
      // },
      DISCOUNT_AMOUNT: {
        base: "number",
        caption: "Discount amount",
      },
      AUSWAHL: {
        base: "choice",
        caption: "Auswahl",
        values: {
          1: {
            caption: "öadsfad",
            captions: {},
            order: 0,
          },
          2: {
            caption: "öadsfad2",
            captions: {},
            order: 1,
          },
        },
      },
    },
  },
  APARTMENT: {
    nextFieldId: 7,
    caption: "Apartment",
    fields: {
      NUMBER: {
        base: "string",
        caption: "Number",
      },
      TYPE: {
        base: "string",
        caption: "Type",
      },
      BEDS: {
        base: "number",
        caption: "Beds",
      },
      PRICE: {
        base: "number",
        caption: "Price",
      },
      BOOKING_ID: {
        base: "rev",
        caption: "Booking",
        refFieldId: "APARTMENT_ID",
      },
      BOOKING_STATUS: {
        base: "string",
        caption: "Booking status",
      },
    },
  },
  CUSTOMER: {
    nextFieldId: 7,
    caption: "Customer",
    fields: {
      NAME: {
        base: "string",
        caption: "Name",
      },
      EMAIL: {
        base: "string",
        caption: "Email",
      },
      AGE: {
        base: "number",
        caption: "Age",
      },
      // BOOKING_ID: {
      //   base: 'rev',
      //   caption: 'Booking',
      //   refFieldId: 'CUSTOMER_ID',
      // },
      PHONE_NUMBER: {
        base: "string",
        caption: "Phone number",
      },
      BIRTHDAY: {
        base: "date",
        caption: "Birthday",
      },
      // LAST_NAME: {
      //   base: 'string',
      //   caption: 'Last name',
      // },
    },
  },
  //   PRICING: {
  //     caption: 'Pricing',
  //     fields: {
  //       BASE_PRICE: {
  //         caption: 'Base price',
  //         base: 'number',
  //       },
  //       WEEKEND: {
  //         caption: 'Weekend',
  //         base: 'number',
  //       },
  //       WEEKLY: {
  //         caption: 'Weekly',
  //         base: 'number',
  //       },
  //       MONTHLY: {
  //         caption: 'Monthly',
  //         base: 'number',
  //       },
  //       SEASONAL: {
  //         caption: 'Seasonal',
  //         base: 'number',
  //       },
  //       HOLIDAY: {
  //         caption: 'Holiday',
  //         base: 'number',
  //       },
  //       ROOM_TYPE: {
  //         caption: 'Room type',
  //         base: 'choice',
  //         values: {
  //           1: {
  //             caption: 'Single',
  //             captions: {},
  //             order: 0,
  //           },
  //           2: {
  //             caption: 'Double',
  //             captions: {},
  //             order: 1,
  //           },
  //           3: {
  //             caption: 'Suite',
  //             captions: {},
  //             order: 2,
  //           },
  //         },
  //       },
  //       DESCRIPTION: {
  //         caption: 'Description',
  //         base: 'string',
  //       },
  //     },
  //   },
  //   BOOKINGSTATUS: {
  //     caption: 'Bookingstatus',
  //     fields: {
  //       DESCRIPTION: {
  //         caption: 'Description',
  //         base: 'string',
  //       },
  //       STATUS: {
  //         caption: 'Status',
  //         base: 'choice',
  //         values: {
  //           1: {
  //             caption: 'AVAILABLE',
  //             captions: {},
  //             order: 0,
  //           },
  //           2: {
  //             caption: 'BOOKED',
  //             captions: {},
  //             order: 1,
  //           },
  //           3: {
  //             caption: 'INACTIVE',
  //             captions: {},
  //             order: 2,
  //           },
  //         },
  //       },
  //       APARTMENT_ID: {
  //         caption: 'Apartment id',
  //         base: 'string',
  //       },
  //       BOOKING_ID: {
  //         caption: 'Booking id',
  //         base: 'string',
  //       },
  //       FROM_DATE: {
  //         caption: 'From date',
  //         base: 'date',
  //       },
  //       TO_DATE: {
  //         caption: 'To date',
  //         base: 'date',
  //       },
  //       BOOKING_STATUS: {
  //         caption: 'Booking status',
  //         base: 'choice',
  //         values: {
  //           1: {
  //             caption: 'Available',
  //             captions: {},
  //             order: 0,
  //           },
  //           2: {
  //             caption: 'Reserved',
  //             captions: {},
  //             order: 1,
  //           },
  //           3: {
  //             caption: 'Inactive',
  //             captions: {},
  //             order: 2,
  //           },
  //         },
  //       },
  //     },
  //   },
  //   COIN: {
  //     caption: 'Coin',
  //     fields: {
  //       NAME: {
  //         caption: 'Name',
  //         base: 'string',
  //       },
  //       YEAR: {
  //         caption: 'Year',
  //         base: 'number',
  //       },
  //       COUNTRY: {
  //         caption: 'Country',
  //         base: 'string',
  //       },
  //       DENOMINATION: {
  //         caption: 'Denomination',
  //         base: 'number',
  //       },
  //       MATERIAL: {
  //         caption: 'Material',
  //         base: 'string',
  //       },
  //       WEIGHT: {
  //         caption: 'Weight',
  //         base: 'number',
  //       },
  //       DIAMETER: {
  //         caption: 'Diameter',
  //         base: 'number',
  //       },
  //     },
  //   },
};

export const exampleDatabase: NinoxDatabaseSchema = {
  seq: 1,
  version: 1,
  nextTypeId: 1,
  types: exampleTables,
};

//   {
//     id: 'AUDIOBOOK',
//     name: 'Audiobook',
//     fields: [
//       { id: 'TITLE', name: 'Title', type: 'string' },
//       { id: 'AUTHOR', name: 'Author', type: 'string' },
//       { id: 'NARRATOR', name: 'Narrator', type: 'string' },
//       { id: 'RUNTIME', name: 'Runtime', type: 'number' },
//       { id: 'RELEASEDATE', name: 'ReleaseDate', type: 'date' },
//     ],
//   },
//   {
//     id: 'SERVER',
//     name: 'Server',
//     fields: [
//       { id: 'NAME', name: 'Name', type: 'string' },
//       { id: 'AGE', name: 'Age', type: 'number' },
//       { id: 'HIREDATE', name: 'HireDate', type: 'date' },
//       { id: 'SHIFT', name: 'Shift', type: 'time' },
//       { id: 'RATING', name: 'Rating', type: 'number' },
//       { id: 'NOTES', name: 'Notes', type: 'string' },
//       { id: 'ID', name: 'ID', type: 'string' },
//       {
//         id: 'ROLE',
//         name: 'Role',
//         type: 'choice',
//         choices: [
//           { id: '1', caption: 'Waiter', captions: {}, order: 0 },
//           { id: '2', caption: 'Chef', captions: {}, order: 1 },
//         ],
//       },
//       {
//         id: 'STATUS',
//         name: 'Status',
//         type: 'choice',
//         choices: [
//           { id: '1', caption: 'Active', captions: {}, order: 0 },
//           {
//             id: '2',
//             caption: 'Inactive',
//             captions: {},
//             order: 1,
//           },
//         ],
//       },
//     ],
//   },
