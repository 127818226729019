// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ArrowFatLeft,
  ArrowFatRight,
  Calendar,
  CheckCircle,
  Clock,
  Function,
  ListBullets,
  NumberCircleOne,
  TextAlignLeft,
  TextT,
} from "@phosphor-icons/react";
import { NinoxField } from "../ninox-types";

export const ArcBotFieldItem = ({
  field,
}: {
  field: Record<string, NinoxField>;
}) => {
  const fieldValue = Object.values(field)[0];
  const fieldKey = Object.keys(field)[0];

  if (!fieldValue) return null;

  return (
    <div className="flex items-center gap-2 p-2 text-sm rounded-sm cursor-default bg-slate-100">
      <div className="icon" title={fieldValue?.base}>
        {fieldValue.base === "string" && (
          <TextT size={22} className="text-custom-purple" weight="fill" />
        )}

        {fieldValue.base === "boolean" && (
          <CheckCircle className="text-custom-purple" weight="fill" size={22} />
        )}
        {fieldValue.base === "date" && (
          <Calendar size={22} className="text-custom-purple" weight="fill" />
        )}
        {fieldValue.base === "rev" && (
          <ArrowFatLeft
            size={22}
            className="text-custom-purple"
            weight="fill"
          />
        )}
        {fieldValue.base === "ref" && (
          <ArrowFatRight
            size={22}
            className="text-custom-purple"
            weight="fill"
          />
        )}
        {fieldValue.base === "choice" && (
          <ListBullets size={22} className="text-custom-purple" weight="fill" />
        )}
        {fieldValue.base === "files" && (
          <ListBullets size={22} className="text-custom-purple" weight="fill" />
        )}
        {fieldValue.base === "number" && (
          <NumberCircleOne
            size={22}
            className="text-custom-purple"
            weight="fill"
          />
        )}
        {fieldValue.base === "time" && (
          <Clock size={22} className="text-custom-purple" weight="fill" />
        )}
        {fieldValue.base === "fn" && (
          <Function size={22} className="text-custom-purple" weight="fill" />
        )}
      </div>
      <span title={fieldValue?.caption} className="truncate">
        {fieldValue?.caption} ({fieldKey})
      </span>
    </div>
  );
};
