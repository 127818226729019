import { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import routing components
import { ApiClient } from "./ApiClient";
import LoadingSpinnerDialog from "./components/LoadingSpinnerDialog";
import "./index.css";
import { NinoxTableUpdate } from "./ninox-types";
import { ArcBotApp, ArcBotAppRoute } from "./pages/ArcBotApp/ArcBotApp";
import {
  FeatureRequests,
  FeatureRequestsRoute,
} from "./pages/FeatureRequests/FeatureRequests";
import { Terms, TermsRoute } from "./pages/Policies/Terms";
import WaitlistConfirmed from "./pages/WaitlistConfirmed";
import WaitlistRequested from "./pages/WaitlistRequested";
import { SignedInUser } from "./types";

export const MyContext = createContext<{
  loginOpen: boolean;
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  ninoxDatabaseName: string;
  ninoxTables: Record<string, NinoxTableUpdate> | undefined;
  globalCode: string | undefined;
  setGlobalCode: (globalCode: string | undefined) => void;
  setLoginOpen: (loginOpen: boolean) => void;
  setNinoxDatabaseName: (databaseName: string) => void;
  setNinoxTables: (ninoxTables: Record<string, NinoxTableUpdate>) => void;
  setSignedInUser: (signedInUser: SignedInUser | undefined) => void;
  signedInUser: SignedInUser | undefined;
  stage: string;
  isLinkActive: boolean;
  setIsLinkActive: (isLinkActive: boolean) => void;
  databaseConnected: boolean;
  setDatabaseConnected: (databaseConnected: boolean) => void;
  apiClient: ApiClient;
  userPoolId: string;
  userPoolClientId: string;
  // arcBotLambdaUrl: string;
}>({
  loginOpen: false,
  isLoading: false,
  setLoading: () => {},
  ninoxTables: undefined,
  ninoxDatabaseName: "",
  globalCode: undefined,
  setGlobalCode: () => {},
  setLoginOpen: () => {},
  setNinoxDatabaseName: () => {},
  setNinoxTables: () => {},
  setSignedInUser: () => {},
  signedInUser: undefined,
  stage: "",
  isLinkActive: false,
  setIsLinkActive: () => {},
  databaseConnected: false,
  setDatabaseConnected: () => {},
  apiClient: new ApiClient("", "", "", "", undefined),
  userPoolId: "",
  userPoolClientId: "",
  // arcBotLambdaUrl: "",
});

export function App() {
  const [ninoxTables, setNinoxTables] =
    useState<Record<string, NinoxTableUpdate>>();
  const [ninoxDatabaseName, setNinoxDatabaseName] = useState("");
  const [globalCode, setGlobalCode] = useState<string>();
  const [signedInUser, setSignedInUser] = useState<SignedInUser>();
  const [loginOpen, setLoginOpen] = useState(false);
  const [ninoxPublicLambdaUrl, setNinoxPublicLambdaUrl] = useState<string>();
  const [ninoxPrivateLambdaUrl, setNinoxPrivateLambdaUrl] = useState<string>();
  const [stage, setStage] = useState("");
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [databaseConnected, setDatabaseConnected] = useState(false);
  const [userPoolId, setUserPoolId] = useState("");
  const [userPoolClientId, setUserPoolClientId] = useState("");
  const [arcBotLambdaUrl, setArcBotLambdaUrl] = useState("");
  const [arcBotReactLambdaUrl, setArcBotReactLambdaUrl] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      const response = await fetch("/runtime-config.json");
      const runtimeConfig = await response.json();
      // console.log(`runtimeConfig=${JSON.stringify(runtimeConfig)}`);
      setNinoxPublicLambdaUrl(runtimeConfig.ninoxPublicLambdaUrl);
      setNinoxPrivateLambdaUrl(runtimeConfig.ninoxPrivateLambdaUrl);
      setUserPoolId(runtimeConfig.userPoolId);
      setUserPoolClientId(runtimeConfig.userPoolClientId);
      setStage(runtimeConfig.stage);
      setArcBotLambdaUrl(runtimeConfig.arcBotLambdaUrl);
      setArcBotReactLambdaUrl(runtimeConfig.arcBotReactLambdaUrl);
    })();
  }, []);

  const queryClient = new QueryClient();

  if (!ninoxPublicLambdaUrl || !ninoxPrivateLambdaUrl || !stage)
    return <div>Wait runtime fetching</div>;

  return (
    <QueryClientProvider client={queryClient}>
      <MyContext.Provider
        value={{
          stage,
          isLoading,
          setLoading,
          loginOpen,
          setLoginOpen,
          setNinoxTables,
          setGlobalCode,
          globalCode,
          setNinoxDatabaseName,
          setSignedInUser,
          signedInUser,
          ninoxTables,
          ninoxDatabaseName,
          isLinkActive,
          setIsLinkActive,
          databaseConnected,
          setDatabaseConnected,
          apiClient: new ApiClient(
            ninoxPrivateLambdaUrl,
            ninoxPublicLambdaUrl,
            arcBotLambdaUrl,
            arcBotReactLambdaUrl,
            signedInUser
          ),
          userPoolId,
          userPoolClientId,
          // arcBotLambdaUrl,
        }}
      >
        <LoadingSpinnerDialog isOpen={isLoading} />
        <BrowserRouter>
          <Routes>
            <Route
              // path="/"
              path={ArcBotAppRoute}
              element={<ArcBotApp />}
            />
            <Route
              path={`${ArcBotAppRoute}/s/:sessionId`}
              element={<ArcBotApp />}
            />
            <Route path={FeatureRequestsRoute} element={<FeatureRequests />} />
            <Route path={TermsRoute} element={<Terms />} />
            <Route path="/waitlistRequested" element={<WaitlistRequested />} />
            <Route path="/waitlistConfirmed" element={<WaitlistConfirmed />} />
          </Routes>
        </BrowserRouter>
      </MyContext.Provider>
    </QueryClientProvider>
  );
}
