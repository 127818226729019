import "./logo.css";

const Logo = () => {
  return (
    <svg className="arcbot-logo" viewBox="0 0 307.67 85.29">
      <defs>
        <linearGradient
          id="Neues_Verlaufsfeld_2"
          data-name="Neues Verlaufsfeld 2"
          x1="-23.21"
          y1="63.01"
          x2="308.81"
          y2="3.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#b28af2" />
          <stop offset="1" stopColor="#5138ab" />
        </linearGradient>
        <linearGradient
          id="Neues_Verlaufsfeld_2-2"
          data-name="Neues Verlaufsfeld 2"
          x1="-21.32"
          y1="73.55"
          x2="310.7"
          y2="14.01"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-3"
          data-name="Neues Verlaufsfeld 2"
          x1="-20.11"
          y1="80.25"
          x2="311.9"
          y2="20.71"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-4"
          data-name="Neues Verlaufsfeld 2"
          x1="-21.28"
          y1="73.76"
          x2="310.74"
          y2="14.22"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-5"
          data-name="Neues Verlaufsfeld 2"
          x1="-20.39"
          y1="78.73"
          x2="311.63"
          y2="19.18"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-6"
          data-name="Neues Verlaufsfeld 2"
          x1="-18.93"
          y1="86.86"
          x2="313.09"
          y2="27.32"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-7"
          data-name="Neues Verlaufsfeld 2"
          x1="-18.7"
          y1="88.14"
          x2="313.32"
          y2="28.6"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-8"
          data-name="Neues Verlaufsfeld 2"
          x1="-15.43"
          y1="106.37"
          x2="316.59"
          y2="46.82"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
        <linearGradient
          id="Neues_Verlaufsfeld_2-9"
          data-name="Neues Verlaufsfeld 2"
          x1="-14.89"
          y1="109.39"
          x2="317.13"
          y2="49.85"
          xlinkHref="#Neues_Verlaufsfeld_2"
        />
      </defs>
      <g id="arcBot">
        <g id="arcBotLogo">
          <g id="starGroup">
            <path
              id="star3"
              className="cls-9"
              d="m289.21,7.69h3.08v3.08c0,.85.69,1.54,1.54,1.54s1.54-.69,1.54-1.54v-3.08h3.08c.85,0,1.54-.69,1.54-1.54s-.69-1.54-1.54-1.54h-3.08V1.54c0-.85-.69-1.54-1.54-1.54s-1.54.69-1.54,1.54v3.08h-3.08c-.85,0-1.54.69-1.54,1.54s.69,1.54,1.54,1.54h0Z"
            />
            <path
              id="star2"
              className="cls-6"
              d="m306.13,13.84h-1.54v-1.54c0-.85-.69-1.54-1.54-1.54s-1.54.69-1.54,1.54v1.54h-1.54c-.85,0-1.54.69-1.54,1.54s.69,1.54,1.54,1.54h1.54v1.54c0,.85.69,1.54,1.54,1.54s1.54-.69,1.54-1.54v-1.54h1.54c.85,0,1.54-.69,1.54-1.54s-.69-1.54-1.54-1.54Z"
            />
            <path
              id="star1"
              className="cls-5"
              d="m297.96,23.3l-9.92-3.65-3.65-9.93c-.31-.84-.97-1.51-1.82-1.82-1.59-.58-3.35.23-3.93,1.82l-3.65,9.92-9.93,3.65c-.84.31-1.51.97-1.82,1.82-.58,1.59.23,3.35,1.82,3.93l9.93,3.65,3.66,9.91c.31.84.97,1.51,1.82,1.82,1.59.58,3.35-.23,3.93-1.82l3.65-9.92,9.93-3.65c1.21-.44,2.01-1.59,2-2.87,0-1.28-.81-2.42-2.02-2.85h0Z"
            />
          </g>
          <g id="arcBotText">
            <path
              id="a"
              className="cls-4"
              d="m0,73.36c0-10.09,8.92-12.07,20.04-13.46l10.61-1.32c0-4.9-3.44-5.34-7.31-5.34-4.75,0-8.63,1.54-10.53,5.85l-11.92-1.9c2.05-8.27,10.68-12.66,22.09-12.66,17.78,0,20.85,7.17,20.85,16.09v15.73c0,2.41.15,5.05,1.02,7.46h-13.53c-.37-1.17-.58-2.41-.73-3.88h-.59c-3.95,4.24-9.88,5.34-14.78,5.34-7.97,0-15.22-3-15.22-11.92Zm18.51,2.2c6.44,0,12.65-2.78,12.65-7.68v-.44l-10.17,1.39c-5.27.73-7.9,1.32-7.9,3.8,0,2.27,2.27,2.93,5.41,2.93Z"
            />
            <path
              id="r"
              className="cls-1"
              d="m50.25,46.01h12.58v8.12h.51c1.46-5.92,6.73-9.58,14.12-9.58v12.44c-9.58,0-14.48,4.02-14.48,14.63v12.22h-12.73v-37.82Z"
            />
            <path
              id="c"
              className="cls-3"
              d="m78.56,64.88c0-12.44,9.95-20.34,24.8-20.34,12.88,0,21.43,5.19,23.48,15.51l-12.14,1.1c-1.32-4.83-4.9-6.51-11.49-6.51-7.17,0-11.48,3.88-11.48,10.24,0,5.63,4.32,10.24,11.48,10.24,6.58,0,10.17-1.68,11.49-6.51l12.14,1.1c-2.05,10.31-10.9,15.58-23.48,15.58-14.85,0-24.8-7.97-24.8-20.41Z"
            />
            <path
              id="B"
              className="cls-8"
              d="m132.1,31.3h35.92c11.63,0,17.41,4.9,17.41,14.04,0,4.83-2.34,8.85-5.92,10.9,4.83,1.9,7.97,5.49,7.97,12,0,8.85-4.9,15.58-19.46,15.58h-35.92V31.3Zm33.5,20.41c1.98,0,6.51,0,6.51-4.68s-4.46-4.61-7.17-4.61h-19.6v9.29h20.26Zm-.15,20.63c2.78,0,7.97,0,7.97-5.49,0-4.9-4.97-4.9-8.12-4.9h-19.97v10.39h20.12Z"
            />
            <path
              id="o"
              className="cls-7"
              d="m191.05,64.88c0-13.17,10.24-20.34,24.65-20.34s24.65,7.17,24.65,20.34-10.24,20.41-24.65,20.41-24.65-7.17-24.65-20.41Zm24.5,10.24c6.95,0,11.7-3.88,11.7-10.24s-4.75-10.24-11.7-10.24-11.41,3.88-11.41,10.24,4.68,10.24,11.41,10.24Z"
            />
            <path
              id="t"
              className="cls-2"
              d="m248.83,73.29v-18.14h-5.78v-9.14c6.66-.44,9.07-3.15,9.73-10.46h9.22v10.46h9.14v9.14h-9.14v13.75c0,3.66,1.83,4.97,4.24,4.97,1.46,0,3.22-.51,4.9-1.39v10.61c-3.15,1.46-6.07,2.19-9.58,2.19-8.19,0-12.73-3.95-12.73-12Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
