import { ThumbsUp, ThumbsDown } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { set } from "zod";
import { MyContext } from "../../App";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Button } from "../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

export const Feedback = ({
  comment,
  setComment,
  submitted,
  setSubmitted,
  rating,
  setRating,
  sessionId,
}: {
  submitted: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  rating: boolean | undefined;
  setRating: Dispatch<SetStateAction<boolean | undefined>>;
  setComment: Dispatch<SetStateAction<string | undefined>>;
  comment: string | undefined;
  sessionId: string;
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { stage, ninoxTables, apiClient } = useContext(MyContext);

  return (
    <div className="flex gap-4 pr-5">
      <div className="cursor-pointer">
        <TooltipProvider>
          <div className="flex gap-4">
            <Tooltip>
              <TooltipTrigger asChild>
                <ThumbsDown
                  onClick={() => {
                    if (!submitted) {
                      setRating(rating === false ? undefined : false);
                      setDialogIsOpen(true);
                    }
                  }}
                  className="hover:fill-primary"
                  size={24}
                  weight={rating !== undefined && !rating ? "fill" : undefined}
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>Not as expected</p>
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <ThumbsUp
                  onClick={() => {
                    if (!submitted) {
                      setRating(rating === false ? undefined : true);
                      setDialogIsOpen(true);
                    }
                  }}
                  className="hover:fill-primary"
                  size={24}
                  color="#0f0f0f"
                  weight={
                    rating !== undefined && !!rating && rating
                      ? "fill"
                      : undefined
                  }
                />
              </TooltipTrigger>
              <TooltipContent>
                <p>Great Result</p>
              </TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      </div>
      <Dialog
        open={dialogIsOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen && !submitted) {
            setRating(undefined);
          }

          setDialogIsOpen(isOpen);
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            {!submitted && (
              <DialogTitle>
                <div className="flex items-center gap-2">
                  {rating && (
                    <ThumbsUp
                      className="hover:fill-primary"
                      size={28}
                      color="#0f0f0f"
                      weight="fill"
                    />
                  )}
                  {!rating && (
                    <ThumbsDown
                      className="hover:fill-primary"
                      size={28}
                      color="#0f0f0f"
                      weight="fill"
                    />
                  )}
                  Share additional feedback
                </div>
              </DialogTitle>
            )}
            {!submitted && (
              <DialogDescription className="text-xs">
                Your feedback will help us improve your experience.
              </DialogDescription>
            )}
          </DialogHeader>
          {!submitted && (
            <div className="flex items-center space-x-2">
              <div className="grid flex-1 gap-2">
                <Textarea
                  id="feedback"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder={
                    rating
                      ? "Tell us what was good..."
                      : "Tell us why the result was not as expected ..."
                  }
                />
              </div>
            </div>
          )}
          {!submitted && (
            <div className="sm:justify-start">
              <Button
                onClick={async () => {
                  setIsLoading(true);
                  setSubmitted(true);
                  try {
                    const response = await apiClient.createUserFeedback({
                      comment,
                      rating,
                      sessionId,
                      stage,
                    });
                    setIsLoading(false);
                    return response.data;
                  } catch (error) {
                    console.error(error);
                    throw error;
                  }
                }}
                type="button"
                className="text-white bg-custom-purple hover:bg-custom-purple-100"
              >
                {isLoading ? <LoadingSpinner /> : "Submit"}
              </Button>
            </div>
          )}
          {submitted && (
            <div className="flex items-center h-16 py-20 space-x-2">
              <div className="flex flex-col items-center flex-1 gap-2">
                <div className="text-5xl">🙏</div>
                <p className="font-bold">Thank you for your feedback!</p>
                <Button
                  onClick={() => {
                    setDialogIsOpen(false);
                    // setSubmitted(false);
                    // setRating(undefined);
                    // setComment(undefined);
                  }}
                  type="button"
                  className="text-white bg-custom-purple hover:bg-custom-purple-100"
                >
                  Back
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
