import React from "react";
import { LoadingSpinner } from "./LoadingSpinner";
import { Dialog, DialogContent } from "./ui/dialog";

const LoadingSpinnerDialog = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent className="flex items-center justify-center h-screen bg-gray-500 bg-opacity-20">
        <LoadingSpinner />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingSpinnerDialog;
