import axios, { AxiosInstance } from "axios";
import {
  NinoxDatabase,
  NinoxDatabaseSchema,
  NinoxField,
  NinoxTable,
  NinoxTableUpdate,
} from "./ninox-types";

export interface Team {
  id: string;
  name: string;
}

export interface Database {
  id: string;
  name: string;
}

class NinoxClient {
  private httpClient: AxiosInstance;
  teamId: string | undefined;
  databaseId: string | undefined;

  constructor(
    ninoxUrl: string | undefined,
    apiKey: string
    // teamId: string,
    // databaseId: string
  ) {
    this.httpClient = axios.create({
      baseURL: ninoxUrl,
      // baseURL: `https://arc-rider.ninoxdb.de/v1/teams/${teamId}/databases/${databaseId}`,
      headers: {
        Authorization: `Bearer ${apiKey}`,
        ["Content-Type"]: "application/json",
      },
    });
  }

  setTeamId(teamId: string) {
    this.teamId = teamId;
  }

  setDatabaseId(databaseId: string) {
    this.databaseId = databaseId;
  }

  // async getTables() {
  //   try {
  //     const response = await this.httpClient.get<NinoxTable[]>('/tables');
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error fetching tables:', error);
  //     throw error;
  //   }
  // }

  async getTeams() {
    // try {
    const teams = (await this.httpClient.get<Team[]>(`/v1/teams`)).data;
    // console.log(`teams= ${JSON.stringify(teams)}`);
    return teams;
  }

  async getDatabases() {
    if (!this.teamId) {
      throw new Error("teamId not set");
    }
    // try {
    const databases = (
      await this.httpClient.get<Database[]>(
        `/v1/teams/${this.teamId}/databases`
      )
    ).data;
    // console.log(`teams= ${JSON.stringify(teams)}`);
    return databases;
  }

  async getDatabase() {
    if (!this.teamId || !this.databaseId) {
      throw new Error("teamId or databaseId not set");
    }
    // try {
    const database = (
      await this.httpClient.get<NinoxDatabase>(
        `/v1/teams/${this.teamId}/databases/${this.databaseId}`
      )
    ).data;
    console.log(`database= ${JSON.stringify(database)}`);
    // don't include dashboard table
    // delete schema.types.A;
    return database;
    // } catch (error) {
    //   console.error('Error fetching tables:', error);
    //   throw error;
    // }
  }

  async updateTables(
    tables: Record<string, NinoxTableUpdate>,
    globalCode?: string
  ) {
    // console.log(`tables= ${JSON.stringify(tables)}`);
    // try {
    // get current schema
    const schema = (
      await this.httpClient.get<NinoxDatabaseSchema>(
        `/v1/teams/${this.teamId}/databases/${this.databaseId}/schema`
      )
    ).data;
    console.log(`schema= ${JSON.stringify(schema)}`);
    if (schema.seq === undefined) {
      throw new Error("schema seq shouldn't be empty!");
    }

    // filter fields which not exist in ninox db
    const tablesFiltered = Object.entries(tables)
      .filter((t) => t[1] !== null) //&& schema.types[t[0]] !== undefined
      .map((t) => {
        if (t[1] === null) return t;
        Object.entries(t[1]?.fields ?? {});
        const tableFiltered: typeof t = [
          t[0],
          {
            ...t[1],
            fields: Object.fromEntries(
              Object.entries(t[1]?.fields ?? {}).filter((f) => f[1] !== null)
            ),
          },
        ];
        return tableFiltered;
      });

    // Convert the filtered tables back into an object
    const tableFilteredRecord = Object.fromEntries(tablesFiltered);
    // const tableFilteredRecord = tables;

    const payload = {
      // result: {
      ...schema,
      types: tableFilteredRecord,
      globalCode: globalCode,
      // },
    };

    console.log(`payload= ${JSON.stringify(payload)}`);

    // override types/tables but keep the Dashboard table
    const newSchema = (
      await this.httpClient.post<NinoxDatabaseSchema>(
        `/v1/teams/${this.teamId}/databases/${this.databaseId}/schema`,
        payload
      )
    ).data;

    console.log(`newSchema= ${JSON.stringify(newSchema)}`);

    return newSchema.types;

    // } catch (error) {
    //   console.error('Error fetching tables:', error);
    //   throw error;
    // }
  }
}

export default NinoxClient;
