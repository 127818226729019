/* eslint-disable @typescript-eslint/indent */
import { useEffect } from "react";
import LottieAnimation from "../components/ui/loadingBubble";
import Logo from "../components/ui/logo";

function WaitlistConfirmed() {
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("sessionId");
  const userId = urlParams.get("userId");

  useEffect(() => {
    const webhookURL = `https://arcai.app.n8n.cloud/webhook-test/waitlist-confirm?sessionId=${sessionId}&userId=${userId}`;

    fetch(webhookURL, {
      method: "POST",
    })
      .then((response) => response.text())
      .catch((error) => {
        console.error("Error fetching order data:", error);
      });
  });

  return (
    <div className="flex flex-col items-center justify-center h-screen p-6 bg-custom-lightblue">
      <div className="mb-5">
        <Logo />
      </div>
      <div className="flex flex-col items-center justify-center h-32 p-10 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold text-center">
          Your are on the Waitlist
        </h1>
        <p className="mt-4 text-center">
          Thank you for signing up to the Early Access Waitlist. We will contact
          you as soon as possible.
        </p>
        <b className="text-center">Your Waitlist Id is {userId}.</b>
        <LottieAnimation height={120} width={120} />
        <p className="mb-5 text-sm text-center w-52 text-lighgrey">
          Please check your spam folder if you did not receive any email.
          <br />
          If you still did not receive any email, please contact us at{" "}
          <a href="mailto:office@arc-rider.com">office@arc-rider.com</a>.
        </p>
      </div>
    </div>
  );
}

export default WaitlistConfirmed;
