import React from "react";
import { Avatar, AvatarFallback } from "./ui/avatar";

type AvatarType = "user" | "arcBot";

interface PromptUserOutputItemProps {
  avatarType: AvatarType;
  promptUserName: string;
  promptUserInitials: string;
  promptUserMessage: string;
}

const PromptUserOutputItem: React.FC<PromptUserOutputItemProps> = ({
  avatarType,
  promptUserName,

  promptUserInitials,
  promptUserMessage,
}) => {
  const avatarColor =
    avatarType === "user"
      ? "bg-blue-500 text-white"
      : "bg-custom-purple text-white";

  const showUserElements = avatarType === "user" ? "hidden" : "";
  const avatarInitials =
    avatarType === "user" ? (
      `${promptUserInitials}`
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="#fff"
        viewBox="0 0 256 256"
      >
        <path d="M208,144a15.78,15.78,0,0,1-10.42,14.94l-51.65,19-19,51.61a15.92,15.92,0,0,1-29.88,0L78,178l-51.62-19a15.92,15.92,0,0,1,0-29.88l51.65-19,19-51.61a15.92,15.92,0,0,1,29.88,0l19,51.65,51.61,19A15.78,15.78,0,0,1,208,144ZM152,48h16V64a8,8,0,0,0,16,0V48h16a8,8,0,0,0,0-16H184V16a8,8,0,0,0-16,0V32H152a8,8,0,0,0,0,16Zm88,32h-8V72a8,8,0,0,0-16,0v8h-8a8,8,0,0,0,0,16h8v8a8,8,0,0,0,16,0V96h8a8,8,0,0,0,0-16Z"></path>
      </svg>
    );

  return (
    <div className="flex gap-4 space-x-2 align-top prompt-user-output-item items-top">
      <Avatar className={`${avatarColor}`}>
        <AvatarFallback>{avatarInitials}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col flex-grow gap-10">
        <div className="flex flex-col prompt-output-header">
          <p className="font-semibold prompt-user-name">{promptUserName}</p>
          <p className="prompt-message">{promptUserMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default PromptUserOutputItem;
