import { DotsNine } from "@phosphor-icons/react";
import { ArcBotFieldItem } from "./arcbot-field-item";
import { NinoxTableUpdate } from "../ninox-types";

export const ArcBotTableItem = ({
  table,
}: {
  table: Record<string, NinoxTableUpdate>;
}) => {
  const tableValue = Object.values(table)[0];
  const tableKey = Object.keys(table)[0];

  if (!tableValue?.fields) return <div></div>;

  const fields = Object.entries(tableValue.fields) as
    | [string, unknown][]
    | undefined;

  return (
    <div
      className={
        "p-6 text-slate-700 bg-white border-slate-100 border-2 rounded-lg gap-4 shadow-xl"
      }
    >
      <div className="flex flex-row items-center gap-2 mb-2">
        <DotsNine size={24} color="#444" />
        <h2 className="text-lg font-bold truncate">
          {tableValue?.caption} ({tableKey})
        </h2>
      </div>
      <div className="grid gap-2">
        {fields?.map(([key, value]) => (
          <ArcBotFieldItem key={key} field={{ [key]: value as any }} />
        ))}
      </div>
    </div>
  );
};
