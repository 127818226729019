import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { FeatureRequestList } from "./FeatureRequestList";
import { MyContext } from "../../App";
import { Header } from "../../components/Header";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Button } from "../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";

export const FeatureRequestsRoute = "/featureRequests";

const featureSubmitSchema = z.object({
  title: z.string().min(3),
  description: z.string().min(3),
});

export const FeatureRequests = () => {
  const { setLoginOpen, signedInUser } = useContext(MyContext);

  const form = useForm<z.infer<typeof featureSubmitSchema>>({
    resolver: zodResolver(featureSubmitSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const onSubmit = async ({
    title,
    description,
  }: z.infer<typeof featureSubmitSchema>) => {
    if (!signedInUser) {
      setLoginOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await apiClient.createFeatureRequest({
        title,
        description,
      });
      window.location.reload();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { apiClient } = useContext(MyContext);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-col w-full h-full gap-3 p-5 mt-4 overflow-hidden lg:flex-row items-top">
        <Card className="w-[450px]">
          <CardHeader>
            <CardTitle>Submit Ticket</CardTitle>
            <CardDescription>
              Submit a feature request or report a bug, so it can be upvoted and
              prioritized by us.
            </CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col pb-0 overflow-auto">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-8"
              >
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Title</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Title of your issue"
                          {...field}
                          id="request-title"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Describe the issue"
                          {...field}
                          id="request-description"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* {errorMessage && (
                <div className="text-red-500">{errorMessage}</div>
              )} */}
                <Button disabled={isLoading}>
                  {isLoading ? <LoadingSpinner /> : "Submit"}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
        <FeatureRequestList />
      </div>
    </div>
  );
};
